import { Server as _ } from '@/utils/request2'

class Api extends _ {
  /**
   * 用户基本信息
   * http://zyapi.zerone.com.cn/project/20/interface/api/1031
   * @returns
   */
  getUserInfo () {
    return this.ajax('get', '/zuser/user/general-info')
  }

  /**
   * 提交意见反馈
   * http://zyapi.zerone.com.cn/project/20/interface/api/5584
   * @param {*} type
   * @param {*} pageName
   * @param {*} content
   * @returns
   */
  feedBack(type, pageName, content) {
    return this.ajax('post', '/zuser/common/feedback/create', { type, pageName, content })
  }

  /**
   * 帮助中心验证
  */
  authentication (params) {
    return this.ajax('post', '/zuser/oauth2/authentication', params)
  }

  /**
   * 校验用户是否是白名单，fileviewer 是否禁用复制粘贴
   * http://zyapi.zerone.com.cn/project/20/interface/api/3760
   * @returns
   */
  getCheckUserIsWhite () {
    return this.ajax('get', '/zuser/user/checkUserIsWhite')
  }

  /**
   * 获取最新的发布公告
   * http://zyapi.zerone.com.cn/project/20/interface/api/7024
   * @returns
   */
  getLatestRelease () {
    return this.ajax('get', '/zuser/user/last-notification')
  }

  /**
   * 用户已读发布公告
   * http://zyapi.zerone.com.cn/project/20/interface/api/7016
   * @param {Integer} id
   * @returns
   */
  readChangeLog (id) {
    return this.ajax('post', '/zuser/user/notification-read', { id })
  }

  /**
   * 游客公开字典
   * http://zyapi.zerone.com.cn/project/20/interface/api/7408
   * @returns
   */
  getPublicDict () {
    return this.ajax('get', '/zuser/visitor/public-dict')
  }

  /**
   * 查询所有区号列表
   * http://zyapi.zerone.com.cn/project/20/interface/api/3336
   * @returns
   */

  getPhoneAreaList () {
    return this.ajax('get', '/zuser/visitor/phone-area/list')
  }
}

export default new Api()
