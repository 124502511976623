import axios from 'axios'
import notification from 'ant-design-vue/es/notification'
import store from '@/store'
import router from '@/router'
import { recordInvitationLink } from './invite'
import { clearToken } from './auth'
import { removeResolvedFunc, judgePendingFunc } from './pendings'

const config = {
  baseURL: '/api',
  timeout: 30 * 1000,
  headers: {
    'X-Requested-With': 'WEB',
    'X-Product': process.env.VUE_APP_PRODUCT,
    post: {
      'Content-Type': 'application/json'
    }
  }
}

const service = axios.create(config)
service.interceptors.request.use(
  config => {
    if (service.defaults._cancelToken) {
      // 请求发起之前先检验该请求是否在队列中，如果在就把队列中pending的请求cancel掉
      config._qs = service.defaults._qs
      judgePendingFunc(config)
    }
    const token = store.state.token || window.localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = token
      config.headers['X-Page-Name'] = router.history.current.name
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (service.defaults._cancelToken) {
      // 请求发起之前先检验该请求是否在队列中，如果在就把队列中pending的请求cancel掉
      removeResolvedFunc({
        ...response.config,
        ...{
          _qs: service.defaults._qs
        }
      })
    }
    const res = response.data || {}
    if (res.code !== 0) {
      const isJosnUrl = response.config.url.endsWith('.json')
      try {
        !isJosnUrl && console.error(res, response.config.url)
      } catch (err) {
        console.error(err)
      }

      switch (res.code) {
        case 10001:
          (function () {
            clearToken()

            const inIframe = window.self !== window.top
            if (inIframe) {
              window.parent.postMessage({ zerone: 'auth' }, '*') // for iframe user
            }

            recordInvitationLink(window.location.href)

            router.push({ name: 'login' })
          })()
          break
        case 10002: // http://zentao.zerone.com.cn/task-view-2816.html
          clearToken()
          store.commit('changeState', { key: 'token', value: '' })
          store.commit('changeState', { key: 'logout', value: 1 })
          router.push({ name: 'login' })
          break
        case 30001:
          router.push({ name: 'permission-denied' })
          store.commit('setLoading', false)
          break
        case 30100:
          router.push({ name: 'assets-deleted' })
          break
        case 40001:
          notification.error({ message: res.msg })
          // store.commit('changeState', { key: 'loading', value: false })
          store.commit('setLoading', false)
          break
        default:
          if (!isJosnUrl) console.warn('未知异常：', res.msg)
      }
      // 登录，注册需要根据返回的code 显示不同状态，慎改
      return res
    } else {
      return res.data
    }
  },
  error => {
    if (axios.isCancel(error)) {
      console.log('repeated request: ' + error)
    } else {
      // handle error code
    }
    return Promise.reject(error)
  }
)

class Server {
  ajax(type, url, params, config = {}) {
    // 标识是否清空上一个请求
    service.defaults._cancelToken = config.cancelToken
    service.defaults._qs = config.qs
    return service[type](url, params, config)
  }
}

export { Server, service as http }
