import { Server as _ } from '@/utils/request'

class SellerApi extends _ {
  getInfo (dealId) {
    return this.ajax('get', '/zasset/authenticate/seller/info', { params: { dealId } })
  }

  submitInfo (params) {
    return this.ajax('post', '/zasset/authenticate/seller/submit', params)
  }

  updateInfo (params) {
    return this.ajax('post', '/zasset/authenticate/seller/edit', params)
  }

  clearInfo (params) {
    return this.ajax('get', '/zasset/authenticate/seller/clear', params)
  }

  getDealInfo (dealId, sensType = 'SENSITIVE') {
    return this.ajax('get', '/zasset/deal/seller-deal/general-info', { params: { dealId, sensType } })
  }

  // 卖家消息列表
  sellerInvestNews (dealId) {
    return this.ajax('get', '/zasset/deal/seller-deal/due-diligence/list', { params: { dealId } })
  }

  // 回复尽调
  replyInvestNews (params) {
    return this.ajax('post', '/zasset/deal/seller-deal/due-diligence/revert', params)
  }

  // 终止尽调
  stopDeal (params) {
    return this.ajax('post', '/zasset/deal/seller-deal/stop-deal', params)
  }

  /*
  卖家--基金资产包列表
  http://zyapi.zerone.com.cn/project/20/interface/api/1696
  */
  listAssetsFund (params) {
    return this.ajax('post', '/zasset/deal/seller-deal/list/asset/s-fund', params)
  }

  /*
  卖家--项目资产包列表
  http://zyapi.zerone.com.cn/project/20/interface/api/1701
  */
  listAssetProject (params) {
    return this.ajax('post', '/zasset/deal/seller-deal/list/asset/project', params)
  }
}

export default new SellerApi()
