<template>
  <svg class="icon svg-icon" aria-hidden="true" :style="zStyle" @click="$emit('click')">
    <use :xlink:href="icon"></use>
  </svg>
</template>

<script>
export default {
  name: 'svgIcon',
  props: {
    icon: [String],
    zStyle: {
      type: Object,
      default() {
        return {
          width: '18px',
          height: '18px'
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.svg-icon {
  outline: none;
  &:active,
  &:hover {
    outline: none;
  }
}
</style>
