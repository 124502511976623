import { render, staticRenderFns } from "./EmptyModule.vue?vue&type=template&id=efd6076a&scoped=true"
import script from "./EmptyModule.vue?vue&type=script&lang=js"
export * from "./EmptyModule.vue?vue&type=script&lang=js"
import style0 from "./EmptyModule.vue?vue&type=style&index=0&id=efd6076a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efd6076a",
  null
  
)

export default component.exports