/**
 * 实体链接
 *
 * 场景1: 无指定类型实体（股东）跳转优先级：投资机构、基金、LP、项目（增加[preIpoStatus,cnTicker,hkTicker,usTicker]的判断）、基金管理人、普通实体页
 *       <span v-if="scope.holderId" v-link:click :data-link="[scope.holderId]" class="link">{{scope.sharehdName}}</span>
 *       Excel 导出链接：/info/shareholder/100010341447
 * 场景2: 指定类型实体跳转：指定类型、普通实体页
 *       <span v-if="scope.entityId" v-link:click :data-link="[scope.entityId,'fund']" class="link">{{ scope.entityName }}</span>
 * 场景3: 无指定类型实体（股东）跳转优先级:基金 > LP > 项目 > 基金管理人 > 投资机构>普通实体页”的跳转逻辑
 *       <span v-if="scope.entityId" v-link:click3 :data-link="[scope.entityId]" class="link">{{ scope.entityName }}</span>
 *       Excel 导出链接：/info/entity/100010341447
 * 默认一般指定跳转:
 *       <TextEllipsis v-link:click100 :data-link="[scope.lpId, 'lp']">{{ scope.entityName }}</TextEllipsis>
 * 跳转到zerone平台
 * v-link:click101 :data-link="[assetPackageDto.fundManagerId, 'fundManager']"
 *
 * http://zentao.zerone.com.cn/task-view-1401.html
 * http://zentao.zerone.com.cn/task-view-1481.html
 * https://zwiki.zerone.com.cn/pages/viewpage.action?pageId=28083018
 */

import entity from '@/api/entity'
import zerone from '@/zerone.js'

function getEntityInfo(entityId, entityRole, routes) {
  return entity.getEntityTag(entityId).then(roles => {
    return getRoleRoute(entityId, roles, entityRole, routes)
  })
}
function getRoleRoute(entityId, roles, role, routes) {
  // roles: {
  //   cnTicker: null
  //   hkTicker: null
  //   isCompany: false
  //   isFund: false
  //   isFundManager: false
  //   isGp: false
  //   isLp: false
  //   preIpoStatus: null
  //   usTicker: null
  // }
  // const routes = {
  //   gp: 'info-gp',
  //   fund: 'info-fund',
  //   lp: 'info-lp',
  //   company: 'info-project',
  //   fundManager: 'info-manager',
  //   org: 'info-org'
  // }
  let url = routes.org

  // 处理项目跳转
  const handleMarketCompany = (key, roles, routes) => {
    if (key === 'isCompany') {
      // isCompany 为false 时执行逻辑
      // 增加 ['preIpoStatus', 'cnTicker', 'hkTicker', 'usTicker']跳转判断
      const statuLists = ['preIpoStatus', 'cnTicker', 'hkTicker', 'usTicker']
      for (let item = 0; item < statuLists.length; item++) {
        if (roles[statuLists[item]]) {
          url = routes.company
          break
        }
      }
    }
  }
  // 如果有明确类型，则跳明确类型+普通实体替补；不指定类型
  // role: gp | fund | lp | company | fundManager | org
  if (role) {
    // compay 拼成 isCompany
    const key = 'is' + (role.charAt(0).toUpperCase() + role.slice(1))
    if (roles[key]) {
      url = routes[role]
    } else {
      // 处理 'preIpoStatus', 'cnTicker', 'hkTicker', 'usTicker' 的跳转判断
      handleMarketCompany(key, roles, routes)
    }
  } else {
    const routeList = Object.keys(routes)
    for (let i = 0; i < routeList.length; i++) {
      const entity = routeList[i]
      // compay 拼成 isCompany
      const key = 'is' + (entity.charAt(0).toUpperCase() + entity.slice(1))
      if (roles[key]) {
        url = routes[entity]
        break
      } else {
        // 处理 'preIpoStatus', 'cnTicker', 'hkTicker', 'usTicker' 的跳转判断
        handleMarketCompany(key, roles, routes)
      }
    }
  }
  return {
    name: url,
    id: entityId
  }
}

/**
 * 实体跳转
 * @param {*} entityId
 * @param {*} entityRole
 * @returns
 */
function handleOpenEntityLink(entityId, entityRole, context, routes) {
  // fix http://zentao.zerone.com.cn/bug-view-4865.html
  if (!entityId || entityId ==='0') {
    console.warn('无效链接')
    return
  }
  const entityRoleLists = ['gp', 'fund', 'lp', 'company', 'fundManager', 'fundManager']
  getEntityInfo(entityId, entityRole, routes)
    .then(res => {
      console.log(`指定了${entityRole},跳转链接信息--`, res)
      // 指定类型错误提示
      if (entityRole && !entityRoleLists.includes(entityRole)) {
        console.error(`检查${entityRole}传参错误`)
        return false
      }
      const { name, id } = res
      const route = context.$router.resolve({ name, params: { id } })

      if (context.$el.id === 'shareholder-hook') {
        // 股东是中转页，所以要在当前窗口显示
        window.location.replace(route.href)
      } else {
        const name = {
          'info-fund': 'fund',
          'info-lp': 'lp',
          'info-project': 'project',
          'info-manager': 'manager',
          'info-gp': 'gp',
          'info-org': 'org'
        }[res.name]

        const href = zerone.getEntityLink(name, res.id)
        context.$open2({ href })
      }
    })
}

class Click {
  add(entry) {
    entry.el.addEventListener('click', function() {
      try {
        const value = entry.el.attributes['data-link'].value
        const [entityId, entityRole] = value.split(',')
        const routes = {
          gp: 'info-gp',
          fund: 'info-fund',
          lp: 'info-lp',
          company: 'info-project',
          fundManager: 'info-manager',
          org: 'info-org'
        }
        handleOpenEntityLink(entityId, entityRole, entry.context, routes)
      } catch (err) {
        console.error(err)
      }
    })
  }

  // 场景3
  add3(entry) {
    entry.el.addEventListener('click', function(e) {
      try {
        console.log('场景3')
        const value = entry.el.attributes['data-link'].value
        const [entityId, entityRole] = value.split(',')
        // 从"基金>普通实体"的跳转逻辑，变更为“ 基金 > LP > 项目 > 基金管理人 > 投资机构>普通实体页”的跳转逻辑
        const routes = {
          fund: 'info-fund',
          lp: 'info-lp',
          company: 'info-project',
          fundManager: 'info-manager',
          gp: 'info-gp',
          org: 'info-org'
        }
        handleOpenEntityLink(entityId, entityRole, entry.context, routes)
      } catch (err) {
        console.error(err)
      }
    })
  }

  // 默认一般指定跳转
  add100({ el, context }) {
    el.addEventListener('click', function(e) {
      try {
        const value = el.attributes['data-link'].value
        const [id, entityRole] = value.split(',')
        if (!id || !entityRole || id ==='0') {
          console.warn('无效链接')
          return
        }
        const name = {
          fund: 'fund',
          lp: 'lp',
          company: 'project',
          fundManager: 'manager',
          gp: 'gp',
          org: 'org'
        }[entityRole]
        if (!name) {
          console.warn(`请检查链接,${entityRole}`)
          return
        }

        const href = zerone.getEntityLink(name, id)
        context.$open2({ href })
      } catch (err) {
        console.error(err)
      }
    })
  }

  // 在ZDEAL点击主体名称，新开页签跳转到ZERONE，展示对应主体详情页
  add101({ el, context }) {
    el.addEventListener('click', function(e) {
      try {
        const value = el.attributes['data-link'].value
        const [id, entityRole] = value.split(',')
        if (!id || !entityRole) {
          console.warn('无效链接')
          return
        }
        const name = {
          fund: 'fund',
          lp: 'lp',
          company: 'project',
          fundManager: 'manager',
          gp: 'gp',
          org: 'org'
        }[entityRole]
        if (!name) {
          console.warn(`请检查链接,${entityRole}`)
          return
        }

        const href = zerone.getEntityLink(name, id)
        context.$open2({ href })
      } catch (err) {
        console.error(err)
      }
    })
  }
}

const cli = new Click()

export default {
  bind(el, binding, vnode) {
    const { arg } = binding
    const context = vnode.context
    arg.split('|').forEach(item => {
      switch (item) {
        case 'click': // 场景1、2
          cli.add({ el, context })
          break
        case 'click3': // 场景3
          cli.add3({ el, context })
          break
        case 'click100': // 默认一般指定跳转
          cli.add100({ el, context })
          break
        case 'click101': // 在ZDEAL点击主体名称，新开页签跳转到ZERONE，展示对应主体详情页
          cli.add101({ el, context })
          break
        case 'exposure':// 曝光埋点
          break
      }
    })
  }
}
