import Vue from 'vue'
import Button from './Button'
import SvgIcon from './svgIcon'
import NoData from './noData'
import EmptyModule from './EmptyModule'
import Unarchive from './Unarchive'
import TableHead from './TableHead'
import LinkTab from './LinkTab'

Vue.component(Button.name, Button)
Vue.component(SvgIcon.name, SvgIcon)
Vue.component(NoData.name, NoData)
Vue.component(EmptyModule.name, EmptyModule)
Vue.component(TableHead.name, TableHead)
Vue.component(LinkTab.name, LinkTab)
Vue.component(Unarchive.name, Unarchive)
