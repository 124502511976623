import { Server as _ } from '@/utils/request2'

class Api extends _ {
  /**
   * 获取实体类型
   * http://zyapi.zerone.com.cn/project/20/interface/api/3296
   * @param {*} entityId
   */
  getEntityTag (entityId) {
    return this.ajax('get', '/zdata/entity/entityType', { params: { entityId } })
  }

  /**
   * 获取实体 Logo
   * @param {String} entityId
   * @returns
   */
  getEntityLogo (entityId) {
    // return this.ajax('get', '/zvdr/oss/zerone-entity-logo', { params: { entityId }})
    return new Promise(function (resolve) {
      resolve(`${process.env.VUE_APP_LOGO_SITE}/entity_basic_${entityId}.jpg`)
    })
  }

  /**
   * 关注
   * http://zyapi.zerone.com.cn/project/20/interface/api/2141
   * @param {string} collectBasicIdList 关注夹ID 列表，实际使用只会有一个
   * @param {*} entityList 实体列表
   * @param {*} entityType 实体类型
   */
  collectEntity (collectBasicIdList, entityList, entityType) {
    return this.ajax('post', '/zuser/collect/add', { collectBasicIdList, entityList, entityType })
  }

  /**
   * 取消关注
   * http://zyapi.zerone.com.cn/project/20/interface/api/2141
   * @param {*} params
   */
  uncollectEntity (params) {
    return this.ajax('post', '/zuser/collect/cancel', params)
  }

  /**
   * 获取关注信息
   * http://zyapi.zerone.com.cn/project/20/interface/api/2139
   * @param {*} params
   */
  getCollection (params) {
    return this.ajax('get', '/zuser/collect/info', { params })
  }

  /**
   * 关注统计数据
   * http://zyapi.zerone.com.cn/project/20/interface/api/2135
   * @param {string} collectId 关注夹ID
   */
  getCollectionStatics (collectId) {
    return this.ajax('get', '/zuser/collect/statics', { params: { collectId } })
  }

  /**
   * 关注列表
   * http://zyapi.zerone.com.cn/project/20/interface/api/2137
   * @param {string} collectId 关注夹ID
   * @param {string}} entityType 实体类型
   * @param {number} page
   * @param {number} pageSize
   * @param {string} entityName 搜索实体名称
   */
  getCollections (collectId, entityType, page = 1, pageSize = 10, entityName) {
    return this.ajax('post', '/zuser/collect/list', { collectId, entityType, page, pageSize, entityName })
  }

  /**
   * 15546642023
   * 关注夹列表
   * http://zyapi.zerone.com.cn/project/20/interface/api/2544
   * @param {number} entityId 实体ID
   * @param {strin} entityType 实体类型
   */
  getCollectList (entityId, entityType) {
    return this.ajax('get', '/zuser/collect/basic/list', { params: { entityId, entityType } })
  }

  /**
   * http://zyapi.zerone.com.cn/project/20/interface/api/2536
   * @param {string} collectName 关注夹名称
   */
  createCollectionFolder (collectName) {
    return this.ajax('post', '/zuser/collect/basic/add', { collectName })
  }

  /**
   * http://zyapi.zerone.com.cn/project/20/interface/api/2552
   * 删除关注夹
   * @param {string} collectId 关注夹id
   */
  deleteCollectionFolder (collectId) {
    return this.ajax('post', '/zuser/collect/basic/delete', { collectId })
  }

  /**
   * http://zyapi.zerone.com.cn/project/20/interface/api/2560
   * 关注夹重命名
   * @param {string} collectId 关注夹id
   * @param {string} collectName 关注夹名称
   */
  editCollectionFolder (collectId, collectName) {
    return this.ajax('post', '/zuser/collect/basic/rename', { collectId, collectName })
  }

  /**
   * http://zyapi.zerone.com.cn/project/20/interface/api/2568
   * 导入关注-预加载
   * @param {stirng} entityType 实体类型
   * @param {string} importFileId 文件id
   */
  preImportCollections (entityType, importFileId) {
    return this.ajax('post', '/zuser/collect/pre-import', { entityType, importFileId })
  }

  /**
   * 工商vie架构图接口
   * @param {*} inEntityName 必填，vie图表的入口公司名称：普通项目传全名；上市项目传股票名称
   * @param {*} vieTickerCode 必填，vie图最终的顶层上市公司
   * @param {*} investorType 选填，投资者实体的类型，例如 GP ｜ FUND
   * @param {*} investorId  选填，投资者实体的id
   * @returns
   */
  getVieDegrePath (inEntityName, vieTickerCode, investorType = undefined, investorId = undefined) {
    return this.ajax('post', '/zdata/entity/vie-path', { inEntityName, vieTickerCode, investorType, investorId })
  }

  /**
   * http://zyapi.zerone.com.cn/project/20/interface/api/6544
   * vie列表筛选器
   */
  getInvestmentsVieCompanyFilter (data) {
    return this.ajax('post', '/zdata/entity/vie-list/filter', data)
  }

  /**
   * http://zyapi.zerone.com.cn/project/20/interface/api/6552
   * vie列表
   */
  getInvestmentsVieCompanyList (data) {
    return this.ajax('post', '/zdata/entity/vie-list', data)
  }

  /**
   * http://zyapi.zerone.com.cn/project/20/interface/api/7096
   * 退出事件统计接口
   */
  getExitStat (data) {
    return this.ajax('post', '/zdata/event/exit-info/stat', data)
  }

  /**
   * http://zyapi.zerone.com.cn/project/20/interface/api/7104
   * 投资事件统计值
   */
  getInvestmentStat (data) {
    return this.ajax('post', '/zdata/event/investment-stat', data)
  }

  /**
   * http://zyapi.zerone.com.cn/project/20/interface/api/7112
   * 投资机构-管理基金统计值
   */
  getGpManagerFundStat (data) {
    return this.ajax('post', '/zdata/investOrg/manager-fund/stat', data)
  }

  /**
   * http://zyapi.zerone.com.cn/project/20/interface/api/7120
   * 基金管理人-管理基金统计值
   */
  getManagerFundStat (data) {
    return this.ajax('post', '/zdata/fund-manager/manager-fund/stat', data)
  }
}

export default new Api()
