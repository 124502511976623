import './public-path.js'
import Vue from 'vue'
// import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from '@sentry/tracing'
import to from 'await-to-js'
import './plugin/antd-ui'
import './plugin/holder'
import './plugin/message'
import './plugin/zdeal-elements'
import './plugin/zui' // 即将被 zdeal-elements 替换掉
import App from './App.vue'
import appRouter from './router'
import store from './store'
import db from './db.js'
import '@/assets/fonts/iconfont.js'

// 注册全局组件
import './components/index'

import { http } from '@/utils/request'
import { open, open2, permission, link, downloadFileAsync, excludeZeroIsFalse } from '@/utils/libs'
import moment from 'moment'
import cache from '@/utils/cache'
import * as filters from '@/filters'
import track from '@/directives/track'
import lazy from '@/directives/lazy'
import entityLink from '@/directives/entityLink'
import zerone from './zerone.js'

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.directive('track', track)
// 市场资讯图片懒加载
Vue.directive('lazy', lazy)
// 跳转逻辑
Vue.directive('link', entityLink)

Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.$open = open
Vue.prototype.$open2 = open2
Vue.prototype.$link = link
Vue.prototype.$cache = cache
Vue.prototype.$db = db
Vue.prototype.$moment = moment
Vue.prototype.$permission = permission
Vue.prototype.download = downloadFileAsync
Vue.prototype.$to = to
Vue.prototype.$excludeZeroIsFalse = excludeZeroIsFalse

// this.$zerone.getEntityLink()
Vue.prototype.$zerone = zerone

// 初始化神策sdk
Vue.prototype.$sa = window.sensors || {
  login: function () {
    console.log('sa.login')
  },
  track: function (eventName, params) {
    console.log(eventName, params)
  },
  register: function () {
    console.log('sa.register')
  },
  registerPage: function () {
    console.log('sa.registerPage')
  }
}

let router = appRouter
let instance = null

function render (props = {}) {
  const { container } = props

  // Sentry.init({
  //   Vue,
  //   dsn: 'https://256f0e7391ff4c948b273e71fb5c22f3@o4504715557142528.ingest.sentry.io/4504715560353792',
  //   integrations: [
  //     new BrowserTracing({
  //       routingInstrumentation: Sentry.vueRouterInstrumentation(appRouter),
  //       tracePropagationTargets: ['www.zdeal.com.cn', /^\//]
  //     })
  //   ],
  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  //   logErrors: true
  // })

  instance = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount(container ? container.querySelector('#app') : '#app')
}

/**
 * 微应用独立运行时
 */
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

/**
 * 微应用钩子
 */
export async function bootstrap() {
  console.log('[vue] vue app bootstraped')
}

/**
 * 微应用钩子
 */
export async function mount(props) {
  console.log('[vue] props from main framework', props)
  render(props)
}

/**
 * 微应用钩子
 */
export async function unmount() {
  console.log('[vue] vue app unmount')
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
  router = null
}
