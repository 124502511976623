import localforage from 'localforage'

const dbName = 'zdeal'

const config = {
  name: dbName,
  description: '前端缓存数据库'
}

export default {
  init (tableName = '') {
    if (tableName) {
      config.storeName = tableName
    } else {
      console.warn('数据库未指定数据表名称')
    }
    return localforage.createInstance(config)
  }
}
