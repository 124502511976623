<template>
  <a-button :type="type" :disabled="disabled" @click="$emit('click')">
    <slot></slot>
    <a-icon type="loading" v-if="loading"/>
  </a-button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'default'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.ant-btn {
  height: 24px;
  font-size: 12px;
  line-height: 12px;
  padding: 6px 15px;
}

.ant-btn-primary,.ant-btn-danger {
  color:rgba(33,39,52,1);
}

.ant-btn-danger {
  background-color: rgba(255,111,100,1);
}

.ant-btn-cancel {
  border-color: @color-yellow;
  color: @color-yellow;
  background-color: #36352E;
}
</style>
