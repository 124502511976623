<template>
  <a-config-provider :locale="locale" :autoInsertSpaceInButton="false">
    <div id="app">
      <div class="home-loading-layer" v-show="loading">
        <div class="pic-box"></div>
      </div>
      <div class="globalToolTip" id="globalToolTip"></div>
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import * as vuex from 'vuex'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  data() {
    return {
      locale: zhCN
    }
  },
  computed: {
    ...vuex.mapState({
      loading: state => state.loading
    })
  }
}
</script>

<style lang="less">
@import "~zerone-styles";
@import "~zerone-styles/scrollbar.less";
@import "./assets/css/common.less";
@import "./assets/css/global.less";

@import "./assets/css/font-roboto.css";
@import "./assets/fonts/iconfont.css";

@import "./assets/css/antUI.less";
@import "./assets/css/antUI/dropdown.less";
@import "./assets/css/antUI/button.less";
@import "./assets/css/antUI/date-picker.less";
@import "./assets/css/antUI/tip.less";
@import "./assets/css/select.less";
@import "./assets/css/antUISpin.less";
@import "./assets/css/antUIPagination.less";
@import "./assets/css/antUIModal.less";
@import "./assets/themes/introjs-zerone.less";
</style>
