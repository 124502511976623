/*
*created by fengjie.liu 2020.06.20
*这里存储一些静态数据
*用法 import { fundFileTypeLists, shareFileTypeLists,fundRaisFileTypeLists } from '@/utils/constData'
* update by fengjie.liu 2020.06.28
* http://zyapi.zerone.com.cn/project/20/interface/api/1866
* https://docs.qq.com/sheet/DZERHSWNsWllqdVZH?tab=BB08J2
*/

// 基金上传文件类型
export const fundFileTypeLists = [
  {
    id: 0,
    value: 'FUND_SHARE_INTRODUCE',
    fileNameIncludes: '份额介绍',
    label: '基金份额介绍'
  },
  {
    id: 1,
    value: 'TEASER',
    fileNameIncludes: 'TEASER',
    label: 'Teaser'
  },
  {
    id: 2,
    value: 'FUND_PARTNERSHIP_AGREEMENT',
    fileNameIncludes: '协议;合伙协议;LPA;NDA',
    label: '相关协议'
  },
  {
    id: 3,
    value: 'FUND_PROJECT_RESEARCH',
    fileNameIncludes: '项目研究',
    label: '明星项目研究'
  },
  {
    id: 4,
    value: 'FUND_SCHEME_CALCU',
    fileNameIncludes: '方案测算',
    label: '方案测算'
  },
  {
    id: 5,
    value: 'FUND_FINANCIAL_DATA',
    fileNameIncludes: '审计;财务;',
    label: '财务数据'
  },
  {
    id: 6,
    value: 'FUND_REPORT',
    fileNameIncludes: '基金报告;年度报告',
    label: '基金报告'
  },
  {
    id: 7,
    value: 'OTHER',
    fileNameIncludes: '',
    label: '其他'
  }
]

// 企业股权上传文件类型
export const shareFileTypeLists = [
  {
    id: 0,
    value: 'PROJCET_PRODUCT_INTRODUCE',
    fileNameIncludes: '产品介绍;',
    label: '产品介绍'
  },
  {
    id: 1,
    value: 'PROJCET_INDUSTRY_ANALYSIS',
    fileNameIncludes: '行业;',
    label: '行业分析'
  },
  {
    id: 2,
    value: 'PROJCET_PROSPECTUS',
    fileNameIncludes: '招股说明书;招股书',
    label: '招股说明书'
  },
  {
    id: 3,
    value: 'PROJECT_BUSINESS_PLAN',
    fileNameIncludes: '商业计划书;BP',
    label: '商业计划书'
  },
  {
    id: 4,
    value: 'PROJECT_ANALYSIS_REPORT',
    fileNameIncludes: '分析报告;投资建议书',
    label: '项目分析报告'
  },
  {
    id: 5,
    value: 'PROJECT_INVESTMENT_AGREEMENT',
    fileNameIncludes: '协议;NDA;增资协议;投资协议',
    label: '相关协议'
  },
  {
    id: 6,
    value: 'PROJECT_FINANCIAL_DATA',
    fileNameIncludes: '财务;审计',
    label: '财务数据'
  },
  {
    id: 7,
    value: 'TEASER',
    fileNameIncludes: 'TEASER',
    label: 'Teaser'
  },
  {
    id: 8,
    value: 'PROJECT_DD',
    fileNameIncludes: '尽调',
    label: '尽调报告'
  },
  {
    id: 9,
    value: 'PROJECT_BUSINESS_DATA',
    fileNameIncludes: '运营;业务',
    label: '运营/业务数据'
  },
  {
    id: 10,
    value: 'OTHER',
    fileNameIncludes: '',
    label: '其他'
  }
]

// 基金募集上传文件类型
export const fundRaisFileTypeLists = [
  {
    id: 0,
    value: 'FUNDRAISING_MANUAL',
    fileNameIncludes: '募集说明书',
    label: '募集说明书'
  },
  {
    id: 1,
    value: 'FUNDRAISING_RELATED_AGREEMENT',
    fileNameIncludes: '协议;合伙协议;LPA;NDA',
    label: '相关协议'
  },
  {
    id: 2,
    value: 'OTHER',
    fileNameIncludes: '',
    label: '其他'
  }
]
/*
* created by fengjie.liu 2020.06.23
* 资产单位
*/
export const currencyLists = [
  {
    id: 1,
    value: '人民币'
  },
  {
    id: 2,
    value: '美元'
  },
  {
    id: 3,
    value: '加元'
  },
  {
    id: 4,
    value: '欧元'
  },
  {
    id: 5,
    value: '日元'
  },
  {
    id: 6,
    value: '港元'
  },
  {
    id: 7,
    value: '澳元'
  },
  {
    id: 8,
    value: '英镑'
  }
]

export const historyColorSeries = [
  '#FDD82D',
  '#FFB91D',
  '#FF8F00',
  '#FF6F64',
  '#AF9FA4',
  '#BEA177',
  '#E08B77',
  '#ACC9EB',
  '#59C2C0',
  '#78CA29'
]
/**
 * crated by fengjie.liu 2022.10.19
 * ipo、解禁分析、首页同时期添加的一组新的颜色值
 * https://lanhuapp.com/web/#/item/project/detailDetach?tid=127934a4-377f-4de1-80a6-232d6e86561c&pid=013c925b-825c-4c77-8321-1a57bc306646&project_id=013c925b-825c-4c77-8321-1a57bc306646&image_id=1e58a129-5c87-45e3-831e-b67824536e74&fromEditor=true&type=image
 * 用法、import { ipoAndLiftColorSeries } from '@/utils/constData'
 * **/
export const ipoAndLiftColorSeries = [
  '#FC6E06',
  '#0C68AB',
  '#139A82',
  '#ADABAB',
  '#FE9D27',
  '#06BAF4',
  '#B3DC95',
  '#DDDDDD',
  '#FDA867',
  '#42ABF5',
  '#4CE7CC',
  '#BFBFBF',
  '#FEC37D',
  '#96E5FE',
  '#E5F3DB',
  '#F3F1F1',
  '#BD5301',
  '#028BB7',
  '#5F9C32',
  '#7F7F7F'
]
/*
created by fengjie.liu 2020.08.22 搜索过滤条目
 */
export const searchFilterLabel = ['gpList', 'fundList', 'companyList', 'lpList', 'fundManagerList']
/**
 * created by fengjie.liu 2020.09.08 资产三种类型
 * 1、定向邀请链接复制文案
 */
export const mapAssetTypeName = {
  S_FUND: '基金份额',
  PROJECT: '项目股权',
  FUNDRAISING: '基金募集'
}
// 映射deal路由
export const mapAssetTypeDealRouter = {
  S_FUND: 'deal-fund',
  PROJECT: 'deal-project',
  FUNDRAISING: 'deal-raise'
}
// 映射assets 解析路由
export const mapAssetTypeAssetsSummaryRouter = {
  S_FUND: 'assets-fund-summary',
  PROJECT: 'assets-project-summary',
  FUNDRAISING: 'assets-raise-summary'
}
// 映射deal 路由到对应的name
export const mapDealRouterToName = {
  'deal-project': '项目股权',
  'deal-fund': '基金份额',
  'deal-raise': '基金募集'
}
// 映射deal 路由到对应的assetType
export const mapDealRouterToAssetType = {
  'deal-project': 'PROJECT',
  'deal-fund': 'S_FUND',
  'deal-raise': 'FUNDRAISING'
}
// z盘 businessType类型映射对应的路由
export const mapBusinessTypeAssetsRouter = {
  PROJECT: 'project',
  S_FUND: 'fund',
  FUNDRAISING: 'raise'
}
// 根据资产状态，定向邀请文案提示，根据filters中index中enumAssetStatus状态判断
export const mapAssetStatusTip = {
  PARSING: '等待解析中无法进行定向邀请',
  PARSING_REFUSE: '审核不通过无法进行定向邀请',
  DEAL_COMPLETE: '交易已完成无法进行定向邀请',
  DEAL_STOP: '交易已终止无法进行定向邀请'
}

/*
* created by fengjie.liu 2021.01.22
* 映射类型名称
* 用法：
* 1、import { mapEntityName } from '@/utils/constData'
*/
export const mapEntityName = {
  FUND: '基金',
  COMPANY: '项目公司',
  GP: '投资机构',
  LP: 'LP',
  LISTED_COMPANY: '已上市项目',
  'EVENT-IPO': 'IPO事件',
  'EVENT-LIFT': '解禁事件',
  'EVENT-LP': 'LP出资事件',
  'FUND-MANAGER': '基金管理人',
  EVENT_IPO: 'IPO 事件',
  EVENT_LIFT: '解禁事件',
  EVENT_LP: 'LP出资事件',
  FUND_MANAGER: '基金管理人'
}
/*
* created by fengjie.liu 2021.01.22
* 映射dto
* 用法：
* 1、import { mapDto } from '@/utils/constData'
*/
export function mapDto (entityType) {
  return {
    LISTED_COMPANY: 'huntMainListedCompanyDto',
    FUND: 'huntMainFundDto',
    GP: 'huntMainGpDto',
    COMPANY: 'huntMainCompanyDto',
    LP: 'huntMainLpDto'
  }[entityType] || 'FUND'
}

/*
* created by fengjie.liu 2021.01.22
* 映射dto
* 用法：
* 1、import { mapName } from '@/utils/constData'
*/
export function mapName (name) {
  return {
    secName: '证券简称',
    gpName: '投资机构名称',
    projectName: '项目名称',
    companyName: '公司名称',
    fundName: '基金名称',
    investorName: '投资机构',
    fundManagerName: '基金管理人',
    relateFundName: '关联基金',
    relateLpName: '关联LP',
    relateCompanyName: '关联项目',
    lpName: 'LP名称'
  }[name] || 'secName'
}

/*
** created by fengjie.liu 2021.08.03
* 类型列表
* http://zyapi.zerone.com.cn/project/20/interface/api/3856
* 用法：
* 1、import { mapPermission } from '@/utils/constData'
*/
export const mapPermission = {
  ASSET_CREATOR: '资产创建者',
  SELLER_ADMIN: '卖方管理员',
  SELLER_VIEWER: '卖方查看者',
  BUYER_ADMIN: '买方'
}
