import { debouncePv } from '@/utils/pv'

class Click {
  add(entry) {
    entry.el.addEventListener('click', function() {
      try {
        const value = entry.el.attributes['data-track'].value
        const { pageId, itemId } = JSON.parse(value)
        // console.log('track.click json: ', { pageId, itemId })
        pageId && debouncePv({ pageId, itemId })
      } catch (err) {
        console.error(err)
      }
    })
  }
}

const cli = new Click()

/**
 * 埋点记录
 * <span v-track:click data-track='{ "pageId": 111, "itemId": 222 }'>点击</span>
 *
 * 参考：https://juejin.cn/post/6978131459655598094
 */
export default {
  bind(el, binding) {
    const { arg } = binding
    arg.split('|').forEach(item => {
      switch (item) {
        case 'click': // 点击埋点
          cli.add({ el })
          break
        case 'exposure':// 曝光埋点
          break
      }
    })
  }
}
