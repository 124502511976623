import ndaApi from '@/api/nda'

const state = {
  info: {}
}
const mutations = {
  changeState (state, payload = {}) {
    // eslint-disable-next-line no-prototype-builtins
    if (payload.hasOwnProperty('key') && payload.hasOwnProperty('value')) {
      if (state[payload.key] === undefined) {
        console.error('invalid field for state!')
      }
      state[payload.key] = payload.value
    } else {
      console.error('invalid parameters!')
    }
  }
  // 点击跳转页面闪烁
  // scrollToCheckMore () {
  //   const temDom = document.querySelector('#ndaConIds')
  //   temDom.scrollIntoView()
  //   const ndaDom = document.querySelector('#cpNdaShowV')
  //   if (!ndaDom) {
  //     return console.warn('ndaDom 未找到')
  //   }
  //   let countTem = 0
  //   const temTimer = setInterval(() => {
  //     countTem++
  //     if (!(countTem % 2)) {
  //       ndaDom.classList.add('add-nda-bg-shark')
  //     } else {
  //       ndaDom.classList.remove('add-nda-bg-shark')
  //     }
  //     if (countTem >= 5) {
  //       clearInterval(temTimer)
  //     }
  //   }, 200)
  // }
}
const actions = {
  getNdaInfo ({ state }, id) {
    ndaApi.getNDAInfo(id)
      .then(res => {
        if (res) state.info = res
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
