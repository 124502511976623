import { Server as _ } from '@/utils/request'
class SellerApi extends _ {
  // 活跃买家
  activeBuyer (params) {
    return this.ajax('get', '/zasset/statistics/activity/buyer', params)
  }

  // 活跃基金
  getActiveFund (params) {
    return this.ajax('get', '/zasset/statistics/activity/fund', params)
  }

  // 交易近状 交易额及次数
  getTradeRelate (params) {
    return this.ajax('get', '/zasset/statistics/sfund/deal/lastYear', params)
  }

  // 注册
  getTradeRegister (params) {
    return this.ajax('get', '/zasset/statistics/sfund/deal/by-register-year', params)
  }

  // 资产包列表
  getCapitalList (params) {
    return this.ajax('get', '/zasset/deal/seller-deal/list', params)
  }
  // 这个文件以后会慢慢废弃，有新增的请平移到home2.js
}

export default new SellerApi()
