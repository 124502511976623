<style lang="less" scoped>
.warp-table-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:48px;
  background:rgba(49,57,76,1);
  padding-left:20px;
  padding-right:20px;
  border-bottom:2px solid #3D465EFF;
  color:rgba(255,255,255,1);
}
</style>
<template>
<div class="warp-table-head">
  <slot></slot>
</div>
</template>
<script>
export default {
  name: 'TableHead'
}
</script>
