import Vue from 'vue'
import Message from '@/components/Message'

const Msg = {}

const defaultDuration = 1500

Msg.install = (Vue) => {
  const vue = Vue
  const MessageClass = vue.extend(Message) // 将组件变成一个类
  const instance = new MessageClass()
  instance.$mount(document.createElement('div')) // 把这个实例化后的对象挂载到新建的 div 上
  document.body.appendChild(instance.$el) // 在 body 上添加这个 div

  let timer

  const MessageMain = {
    // 返回promise，可以添加then方法，如
    // this.$feedback.success('操作成功').then(res => {
    //   this.loadData(this.params)
    // })
    showMessage (text, duration, type) {
      const closePromise = new Promise(function (resolve, reject) {
        clearTimeout(timer)
        if (duration) {
          timer = setTimeout(() => {
            instance.visible = false
            resolve(true)
          }, duration)
        }
        instance.text = text
        instance.type = type
        instance.duration = duration
        instance.visible = true
      })
      return closePromise
    },
    // 成功
    success (text, duration = defaultDuration, type = 'success') {
      return this.showMessage(text, duration, type)
    },
    // 错误
    error (text, duration = defaultDuration, type = 'error') {
      return this.showMessage(text, duration, type)
    },
    // 警告
    warn (text, duration = defaultDuration, type = 'warning') {
      return this.showMessage(text, duration, type)
    }
  }

  vue.prototype.$feedback = MessageMain
}

Vue.use(Msg)
