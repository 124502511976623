import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { recordInvitationLink } from './invite'
import { clearToken } from './auth'

const config = {
  baseURL: '/api',
  timeout: 30 * 1000,
  headers: {
    'X-Requested-With': 'WEB',
    'X-Product': process.env.VUE_APP_PRODUCT,
    post: {
      'Content-Type': 'application/json'
    }
  }
}

const service = axios.create(config)

service.interceptors.request.use(
  config => {
    const token = store.state.token || window.localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = token
      config.headers['X-Page-Name'] = router.history.current.name
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data || {}
    if (res.code) {
      const isJosnUrl = response.config.url.endsWith('.json')
      try {
        !isJosnUrl && console.error(res, response.config.url)
      } catch (err) {
        console.error(err)
      }
      store.commit('setLoading', false)

      switch (res.code) {
        case 10001:
          (function () {
            clearToken()

            const inIframe = window.self !== window.top
            if (inIframe) {
              window.parent.postMessage({ zerone: 'auth' }, '*') // for iframe user
            }

            recordInvitationLink(window.location.href)

            router.push({ name: 'login' })
          })()
          break
        case 10002: // http://zentao.zerone.com.cn/task-view-2816.html
          clearToken()
          store.commit('changeState', { key: 'token', value: '' })
          store.commit('changeState', { key: 'logout', value: 1 })
          router.push({ name: 'login' })
          break
        case 30001:
          router.push({ name: 'permission-denied' })
          break
        case 30100:
          router.push({ name: 'assets-deleted' })
          break
        default:
          if (!isJosnUrl) console.warn('未知异常：', res.msg)
      }
      return Promise.reject(res)
    } else {
      return res.data
    }
  },
  error => {
    return Promise.reject(error)
  }
)

class Server {
  ajax(type, url, params, config = {}) {
    return service[type](url, params, config)
  }
}

export { Server, service as http }
