import store from 'store'

export default {
  key: '',
  init (key) {
    this.key = key
  },
  get () {
    if (!this.key) console.error('缓存 key 未设置，请在页面初始化后调用 cache.init(this.$route.name)')
    return store.get(this.key)
  },
  set (data) {
    if (!this.key) console.error('缓存 key 未设置，请在页面初始化后调用 cache.init(this.$route.name)')
    const getStore = store.get(this.key)
    return store.set(this.key, { ...getStore, ...data })
  }
}
