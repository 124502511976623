(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("CryptoJS"), require("DataSet"), require("G2"), require("R"), require("VueHolder"), require("numeral"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define(["CryptoJS", "DataSet", "G2", "R", "VueHolder", "numeral", "lodash"], factory);
	else if(typeof exports === 'object')
		exports["zdeal.zerone.com.cn-app"] = factory(require("CryptoJS"), require("DataSet"), require("G2"), require("R"), require("VueHolder"), require("numeral"), require("lodash"));
	else
		root["zdeal.zerone.com.cn-app"] = factory(root["CryptoJS"], root["DataSet"], root["G2"], root["R"], root["VueHolder"], root["numeral"], root["_"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__34294__, __WEBPACK_EXTERNAL_MODULE__63773__, __WEBPACK_EXTERNAL_MODULE__2331__, __WEBPACK_EXTERNAL_MODULE__80841__, __WEBPACK_EXTERNAL_MODULE__34253__, __WEBPACK_EXTERNAL_MODULE__23033__, __WEBPACK_EXTERNAL_MODULE__17607__) {
return 