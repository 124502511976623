/**
 * created by fengjie.liu 2021.02.15 仅用于打点统计
 * **/
import debounce from 'lodash/debounce'
import searchApi from '@/api/search'
/***
 * update by fengjie.liu 2021.10.25
 * fix http://zentao.zerone.com.cn/bug-view-2779.html
 * 统计打点 短时间内打点统计多次
 * 用法：
 * import { debouncePv } from '@/utils/pv'
 * https://zwiki.zerone.com.cn/pages/viewpage.action?pageId=28082532
 **/
export function debouncePv(params) {
  debounce(
    function (params) {
      searchApi.pv(params).then(res => {
        // console.log('pv', res)
      })
    },
    500,
    { leading: true, trailing: false }
  )(params)
}
