<template>
  <div class="empty-module" :style="{ height }" :class="{ 'x': drection === 'x', 'y': drection === 'y' }">
    <span class="icon-box">
      <i class="iconfont" :class="icon" :style="{fontSize:computedFontSize()}"></i>
    </span>
    <span class="tip-text">
      <slot>暂无数据</slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Unarchive',
  props: {
    height: {
      type: String,
      default: '60px'
    },
    icon: {
      type: String,
      default: 'iconmeiyoushuju_icon'
    },
    drection: {
      type: String,
      default: 'x'
    },
    fontSize: {
      type: String
    }
  },
  methods: {
    // 自动计算icon大小
    computedFontSize () {
      if (this.drection === 'x') {
        return this.fontSize || '24px'
      } else if (this.drection === 'y') {
        return this.fontSize || '20px'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.empty-module {
  background-color: @bg-color-module;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:12px;
  font-weight:500;
  color:rgba(164,177,214,0.6);
  line-height:18px;

  .icon-box {
    line-height: 1;

    .iconmeiyoushuju_icon {
      font-size: 18px;
      color: #75809E;
    }
  }

  .tip-text {
    font-size: 12px;
    line-height: 1;
  }
}

.x {
  flex-direction: row;

  .icon-box {
    .iconmeiyoushuju_icon {
      font-size: 24px;
    }
  }
  .tip-text {
    margin-left: 10px;
  }
}

.y {
  flex-direction: column;

  .icon-box {
    .iconmeiyoushuju_icon {
      font-size: 20px;
    }
  }

  .tip-text {
    margin-top: 15px;
  }
}
</style>
