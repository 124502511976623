
// https://juejin.cn/post/6874131616344588302
// https://github.com/ElemeFE/element/blob/dev/packages/image/src/main.vue
// https://juejin.cn/post/6962038712905498631
// https://mp.weixin.qq.com/s/LwLogjepD5UjZTco_s7_Sg
const defImage = 'https://cdn.zdeal.com.cn/img/home/loading.png'
/**
 * 获取给定URL的图像
 * @param {string} url
 */
function fetchImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = url
    image.onload = resolve
    image.onerror = reject
  })
}
/**
 * 预加载图像
 * @param {object} image
 */
function preloadImage(image) {
  const src = image.dataset.src
  if (!src) return
  return fetchImage(src).then(() => { applyImage(image, src) })
}

/**
* 设置图像路径
* @param {object} image
* @param {string} src
*/
function applyImage(img, src) {
  img.src = src
  img.style.width = '100%'
  img.style.height = '100%'
  // img.classList.add('fade-in')
}
export default {
  bind: function(el, binding) {
    el.onerror = () => {
      el.src = defImage
    }

    if (!window.observer) {
      window.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          const lazyImage = entry.target
          if (entry.isIntersecting) {
            window.observer.unobserve(lazyImage)
            preloadImage(lazyImage)
          }
        })
      })
    }
  },
  inserted(el, binding) {
    window.observer.observe(el)
    el.src = defImage
    const styleValue = el.attributes['loading-img-style'].value
    try {
      const { width, height } = JSON.parse(styleValue)
      if (width) {
        el.style.width = width
      }
      if (height) {
        el.style.height = height
      }
    } catch (err) {
      console.error(err)
      el.style.width = '20px'
    }
  },
  componentUpdated: (el, binding) => {
    if (binding.value === binding.oldValue) {
      return false
    }
    window.observer.observe(el)
  },
  unbind: el => {
    window.observer.unobserve(el)
  }
}
