import axios from 'axios'
import { fundFileTypeLists, shareFileTypeLists, fundRaisFileTypeLists } from '@/utils/constData'
import { Decimal } from 'decimal.js'
import pinyin from 'js-pinyin'
import md5 from 'crypto-js/md5'

/**
 * @description 打开新页面
 * @param {String} url 地址
 * 参考 https://gitee.com/fairyever/d2-admin/blob/master/src/libs/util.js
 * import { open } from "@/utils"
 * open("https://www.jd.com/");
 */
export function open (url, target = '_blank') {
  if (process.env.VUE_APP_EMBEDDED === 'true') {
    // 打包为 electorn 时不新开页面
    window.location.href = url
  } else {
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('target', target)
    a.setAttribute('id', 'd2admin-link-temp')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(document.getElementById('d2admin-link-temp'))
  }
}

export function link(to) {
  const href = to.href
  const name = hash(href)
  this.$open(href, name)
}

export function isInvitationLink (url) {
  return url.includes('invite')
}

export function getInvitationLink () {
  return window.sessionStorage.getItem('unauthorized')
}

export function deleteInvitationLink () {
  console.log('删除 unauthorized libs')
  window.sessionStorage.removeItem('unauthorized')
}
/*
* created by fengjie.liu 2020.07.04
* 上传文件MD5
*/
export function md5File (file) {
  return md5(JSON.stringify(file)).toString()
}
/*
* created by fengjie.liu 2020.07.04
* 设置上传文件类型
*/
export function fileType (file, type) {
  // 文件映射列表
  const lists = {
    fund: fundFileTypeLists,
    share: shareFileTypeLists,
    fundRaising: fundRaisFileTypeLists
  }[type]
  // 文件名和匹配关键词都转为大写，进行比较
  // 获取文件名字
  const fileName = file.name.toLocaleUpperCase().split('.')[0]
  const item = lists.filter(x => {
    const _fileNameIncludes = x.fileNameIncludes.toLocaleUpperCase().split(';')
    const _filterByFileName = _fileNameIncludes.filter(n => {
      return n && fileName.includes(n.trim())
    })
    if (_filterByFileName && _filterByFileName.length) {
      return x
    }
  })
  return (item && item[0] && item[0].value) || 'OTHER'
}
/*
* created by fengjie.liu 2020.07.01
* 映射文件类型icon
*/
export function iconExtension (name) {
  if (!name) {
    return '#iconPPT_icon'
  }
  let extension = name.split('.').pop().toUpperCase()
  if (extension === 'DOCX') {
    extension = 'DOC'
  } else if (extension === 'JPEG') {
    extension = 'JPG'
  } else if (extension === 'PPTX') {
    extension = 'PPT'
  } else if (extension === 'XLS') {
    extension = 'XLSX'
  }
  return `#icon${extension}_icon`
}

/**
 * 根据文件类型获取图标
 * @param {string} fileType 文件类型
 */
export function getFileIcon (fileType) {
  let icon = ''
  switch (fileType) {
    case 'DOC':
      icon = '#iconDOC_icon'
      break
    case 'PPT':
      icon = '#iconPPT_icon'
      break
    case 'EXCEL':
      icon = '#iconXLSX_icon'
      break
    case 'PDF':
      icon = '#iconPDF_icon'
      break
    case 'IMG':
      icon = '#iconJPG_icon'
      break
    case 'TXT':
      icon = '#iconTXT_icon'
      break
    case 'FOLDER':
      icon = '#iconfolder'
      break
    default:
      icon = '#iconPDF_icon'
  }

  return icon
}

// 文件大小
export function fileSize (text) {
  if (text < 1024) {
    return `${(text)}B`
  } else if (text < 102400) {
    return `${(text / 1024).toFixed(2)}KB`
  } else {
    return `${(text / 1024 / 1024).toFixed(2)}MB`
  }
}

/**
 * @description 16进制转rgb
 * 参考 https://blog.csdn.net/mossbaoo/article/details/93484635
 * import { colorToRgb } from "@/utils"
 */
export function colorToRgb (value, opacity = 1) {
  // 16进制颜色值的正则
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  // 把颜色值变成小写
  var color = value.toLowerCase()
  if (reg.test(color)) {
    // 如果只有三位的值，需变成六位，如：#fff => #ffffff
    if (color.length === 4) {
      var colorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1))
      }
      color = colorNew
    }
    // 处理六位的颜色值，转为RGB
    var colorChange = []
    for (var i = 1; i < 7; i += 2) {
      colorChange.push(parseInt('0x' + color.slice(i, i + 2)))
    }
    if (opacity !== 1) {
      colorChange.push(opacity)
    }
    return `RGB(${colorChange.join(',')})`
  } else {
    return color
  }
}
/**
 * 前端获取URL参数的方法
 * https://juejin.cn/post/7082306920945549325
 * **/
function getUrlParams(url) {
  const urlStr = url.split('?')[1]
  const urlSearchParams = new URLSearchParams(urlStr)
  const result = Object.fromEntries(urlSearchParams.entries())
  return result
}

function toDataUrl (url) {
  return axios.get(url, { responseType: 'blob' }).then((response) => {
    return response.data
  }).then(blob => {
    return URL.createObjectURL(blob)
  })
}

/**
 * 下载 URL 文件
 * @param {string} url 下载文件地址
 */
export async function downloadFileAsync (url) {
  if (!url) {
    console.error('请检查下载网址')
    return
  }
  const filename = getUrlParams(decodeURIComponent(url)).filename || ''
  /**
   * update by fengjie.liu 20222.05.11
   * ***/
  // const extension = filename.split('.').pop().toLowerCase()
  // if (['jpg', 'png', 'jpeg'].indexOf(extension) > -1) {
  //   const iframe = document.createElement('iframe')
  //   iframe.style.display = 'none'
  //   iframe.style.height = 0
  //   iframe.src = url
  //   document.body.appendChild(iframe)
  //   setTimeout(() => {
  //     iframe.remove()
  //   }, 5 * 60 * 1000)
  // } else {
  //   // 如果文件是图片，则通过 Blob 下载
  //   const link = document.createElement('a')
  //   link.style = 'position: fixed; left -10000px;'
  //   link.href = await toDataUrl(url)
  //   link.download = filename
  //   document.body.appendChild(link)
  //   link.click()
  //   document.body.removeChild(link)
  // }
  const link = document.createElement('a')
  link.style = 'position: fixed; left -10000px;'
  link.href = await toDataUrl(url)
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
/**
 *created by fengjie.liu 2020.07.28
 * @description 获取纯接口请求参数，去除请求接口参数中为null,'',0, false
 * @param {Object}
 */

export function pureDataForm (dataForm) {
  const data = {}
  const arr = Object.keys(dataForm)
  // 去除参数中为null,'',0, false
  for (let i = 0; i < arr.length; i++) {
    const key = arr[i]
    if (dataForm[key]) {
      data[key] = dataForm[key]
    }
  }
  return data
}
/**
 *created by fengjie.liu 2020.07.29
 * @description 获取纯接口请求参数，去除请求接口参数中为null,'',undefined
 * @param {Object}
 */

export function pureParams (params) {
  const data = {}
  const arr = Object.keys(params)
  // 去除参数中为null,'',undefined
  for (let i = 0; i < arr.length; i++) {
    const key = arr[i]
    if (params[key] || params[key] === false) {
      data[key] = params[key]
    }
  }
  return data
}

// http://zyapi.zerone.com.cn/project/20/interface/api/1976
/*
  COMPARE_LISTED_COMPANY: "可比上市公司分享",
  PROJECT_VALUATION: "项目估值分享",
  FUND_VALUATION: "基金投资组合测算分享",
  FUND_TRADE_VALUATION: "基金交易回报分享",
*/
function mapAssetRoute (type) {
  return {
    S_FUND: 'fund',
    PROJECT: 'project',
    FUNDRAISING: 'raise',
    COMPARE_LISTED_COMPANY: 'comparable',
    PROJECT_VALUATION: 'evaluation-company',
    FUND_VALUATION: 'calculator-fund',
    FUND_TRADE_VALUATION: 'calculator-fund-trading',
    INVESTOR_ENTITY: 'dac-edit'
  }[type] || 'fund'
}

/*
* created by fengjie.liu 2020.08.01
* 待处理列表跳转处理
*
  立即处理
  COMPLETE_REQUEST    ：  根据assetId和资产类型跳 资产解析页
  NDA_SEND：  根据dealId和资产类型跳 交易详情页
  DD_CREATE：  根据dealId和资产类型跳 交易详情页内部的尽调列表页
  INVEST_ASSET              ：  根据assetId和资产类型跳 资产解析页
  COMPLETE_REQUEST_LIST("申请查看[列表展示]"),   跳资产设置页  update by fengjie.liu 2021.11.14
  COMPLETE_REQUEST_AGREE("通过完整信息查看申请"),   跳资产解析页 update by fengjie.liu 2021.11.14
  COMPLETE_REQUEST_REFUSED 申请拒绝 跳申请进度页 update by fengjie.liu 2021.11.29
*
*/
export function handleUnDeal (scope) {
  const dynamicType = scope.dynamicType
  const routeKey = mapAssetRoute(scope.taskAssetType)
  let item = {
    name: 'home',
    query: { id: 2 }
  }
  // 根据类型跳转不同的地址
  switch (dynamicType) {
    case 'COMPLETE_REQUEST_LIST':
      // 跳资产设置页
      item = {
        name: `assets-${routeKey}-detail`,
        params: {
          id: scope.dynamicId
        }
      }
      break
    case 'COMPLETE_REQUEST_AGREE':
      // 跳资产解析页
      item = {
        name: `assets-${routeKey}-summary`,
        params: {
          id: scope.dynamicId
        }
      }
      break
    case 'NDA_SEND':
      // 同尽调
      item = {
        name: 'deal-item',
        query: {
          dealId: scope.dynamicId
        }
      }
      break
    case 'DD_CREATE':
      // 资产id
      item = {
        name: 'deal-investigation',
        query: {
          dealId: scope.dynamicId
        }
      }
      break
    case 'COMPLETE_REQUEST':
      // 同邀请
      item = {
        name: `assets-${routeKey}-detail`,
        params: {
          id: scope.dynamicId
        }
      }
      break
    case 'INVEST_ASSET':
      // 增加一个资产id
      item = {
        name: `assets-${routeKey}-summary`,
        params: {
          id: scope.dynamicId
        }
      }
      break
    case 'SHARE_VALUATION':
      // 增加一个资产id
      // console.log('routeKey', routeKey)
      if (routeKey === 'calculator-fund') {
        item = {
          name: routeKey,
          params: {
            id: scope.dynamicId
          }
        }
      } else if (routeKey === 'evaluation-company') {
        item = {
          name: routeKey,
          query: {
            valuationId: scope.dynamicId
          }
        }
      } else if (routeKey === 'comparable') {
        item = {
          name: routeKey,
          query: {
            id: scope.dynamicId
          }
        }
      } else if (routeKey === 'calculator-fund-trading') {
        item = {
          name: routeKey,
          params: {
            id: scope.dynamicId
          }
        }
      } else if (routeKey === 'dac-edit') {
        item = {
          name: routeKey,
          params: {
            id: scope.dynamicId
          }
        }
      }
      break
    case 'COMPLETE_REQUEST_REFUSED':
      item = {
        name: 'deal-apply',
        params: {
          id: scope.dynamicId
        }
      }
      break
    default:
      // 默认代码块
      console.error('新的dynamicType', dynamicType)
  }
  return item
}

/*
* created by fengjie.liu 2020.08.11
* 用于ant desgin vue table 组件filter方法
*/
export function filtersByKey (lists, key, mapkey) {
  return Array.from(new Set(lists.map(n => {
    // if (key === 'statusUpdateUser') {
    //   console.log('n', n, key)
    //   console.log('n[key]', n[key])
    // }
    return n[key] || '-'
  }))).map(x => {
    if (mapkey) {
      return {
        text: mapkey[x] + '',
        value: x + ''
      }
    } else {
      return {
        text: x + '',
        value: x + ''
      }
    }
  })
}
/*
* created by fengjie.liu 2020.08.11
* 用于ant desgin vue table 组件filter方法
*/
export function onFilterByKey (value, record, key) {
  if (value === '-' || value === '-' || value === null) {
    value = null
    return record[key] === value
  } else {
    return record[key] + '' === value
  }
}
/*
* created by fengjie.liu 2020.09.01
* 用于http://localhost:8080/info/fund/100010074238 投资事件判断是否排序操作
*/
export function isSortAction (val) {
  let flag = false
  for (const i in val) {
    flag = 'ASCDESC'.includes(val[i])
  }
  return flag
}

export function hash (str) {
  return md5(str).toString()
}

/*
* created by fengjie.liu 2020.10.20
* 刷新已打开页面/打开未打开页面
* 使用方法
* this.$open2(this.$router.resolve({ name: 'comparable-company', params: { id: this.entityId } }))
* this.$open2(this.$router.resolve({ name: 'documents-exchange-item', query: { folderId: 'FOLDER016459e0e0894690ac32b072cf8504d4' } }))
* this.$open2({ href: url })
*/
export function open2 (route) {
  if (process.env.VUE_APP_EMBEDDED === 'true') {
    // 打包为 electorn 时不新开页面
    window.location.href = route.href
  } else {
    const windowName = hash(route.href)
    console.log('open', { href: route.href, windowName })
    window.open(route.href, windowName)
  }
}
/*
* created by fengjie.liu 2020.11.02
* 用于复制副本
* http://localhost:8080/info/project/100010027531
* http://localhost:8080/user/valuation
*/
export function setCompareName (mapKey = 'compareName', includeKeyWords, inCludesCopyLists, key = 1) {
  function innerSetCompareName (includeKeyWords, inCludesCopyLists, key) {
    const _compareName = `${includeKeyWords}${key}`
    const inCludesCopyListsByCompareName = inCludesCopyLists.map(n => n[mapKey])
    if (inCludesCopyListsByCompareName.includes(_compareName)) {
      key = key + 1
      return innerSetCompareName(includeKeyWords, inCludesCopyLists, key)
    } else {
      return _compareName
    }
  }
  return innerSetCompareName(includeKeyWords, inCludesCopyLists, key)
}

/*
* created by fengjie.liu 2020.11.30
* 用于处理财务数据、项目投融资历史金额回显
* http://localhost:8080/evaluation/company/?valuationId=28515a01787b4fcb9b50e5d6ec445726
* 用法：
* 1、handleUnit(n.grossProfit, this.generalInfo._unitFinancingDataList, 'divide')
* method: 加减乘除：multiply、divide,|add、subtract(业务暂未用到)
*/
export function handleUnit (num, unit, method) {
  if (['', undefined, null, NaN].includes(num)) {
    return ''
  } else {
    if (!method) {
      console.error('method不能为空！')
    }
    if (method === 'divide') {
      return new Decimal(num).div(unit).toNumber()
    } else if (method === 'multiply') {
      return new Decimal(num).mul(unit).toNumber()
    }
  }
}
/*
* created by fengjie.liu 2021.1.7
* 数组中的数相加
* http://localhost:8080/info/project/100010008961
* 用法：
* 1、import { addByKey } from '@/utils/libs'
* method: addByKey(data.dataInfo, 'sharehdNum')
*/
export function addByKey (array, key) {
  const tmpArr = array.map(n => n[key]).filter(n => typeof (n) === 'number')
  // 若是返回数据都为 null
  return tmpArr.length ? array.reduce((prev, cur) => {
    // fix 返回null 报错
    if (cur[key] !== null) {
      return Decimal.add(prev, cur[key] || 0).toNumber()
    } else {
      return prev
    }
  }, 0) : null
}

/*
* created by fengjie.liu 2020.12.07
* 除了0之外都是false，用在输入金额区分0和''
* 用法：
* 1、import { excludeZeroIsFalse } from '@/utils/libs'
*/
export function excludeZeroIsFalse (num) {
  return ['', undefined, null, NaN, false].includes(num)
}

/*
* created by fengjie.liu 2021.04.26 自定义渲染列tooltip
* https://www.jianshu.com/p/76ba0d71bd7c
* 参考  http://localhost:8080/user/valuation
* 用法：
* 1、import { columnsCustomCell } from '@/utils/libs'
*
*/
export function columnsCustomCell (record, rowIndex, key) {
  return {
    on: {
      // 事件
      click: (event) => {}, // 点击行
      dblclick: (event) => {},
      contextmenu: (event) => {},
      mouseenter: (event) => {
        const target = event.target
        // console.log('target.scrollWidth', target, target.scrollWidth)
        // console.log('clientWidth', target.clientWidth)
        // console.log('target.offsetWidth', target.offsetWidth)
        if (target.scrollWidth > target.offsetWidth) {
          // console.log('显示提示')
          record[key] = true
        } else {
          // console.log('不显示提示')
          record[key] = false
        }
      }, // 鼠标移入行
      mouseleave: (event) => {
        record[key] = false
      }
    }
  }
}

/*
* created by fengjie.liu 2021.05.26
* 搜索trim，如(  hi      word )  =>(hi word)
* 用法：
* 1、import { searchTrim } from '@/utils/libs'
*/
export function searchTrim (val = '') {
  return val.trim().replace(/\s+/ig, ' ')
}

/*
* created by fengjie.liu 2021.08.23
* 权限
* 用法：
*/
export function permission (fn) {
  return fn
}

/*
* created by fengjie.liu 2022.04.13
* 换行 https://zwiki.zerone.com.cn/pages/viewpage.action?pageId=28083044
* 用法：import { transformEnter } from '@/utils/libs'
*/
export function transformEnter (str) {
  if (str) {
    return str.replace(/\n/g, '<br/>')
  } else {
    return '-'
  }
}

/**
 * created by fengjie.liu 2022.04.15
 * 滚动到指定位置，首页和专题页
 * 用法：import { scrollTo } from '@/utils/libs'
 *
*/
export function scrollTo (x = 0, y = 0) {
  function getDefaultTarget() {
    return window
  }
  getDefaultTarget().scrollTo(x, y)
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * 将字符串编码为数字
 * @param {*} str 任意字符串
 * @returns Number
 */
export function encodeStringToNumber(str) {
  let result = 0
  for (let i = 0; i < str.length; i++) {
    result = result * 256 + str.charCodeAt(i)
  }
  return result
}

/**
 * 汉字拼音排序函数
 */
export function sortByPinyinFunction (a, b) {
  const pinyinA = pinyin.getFullChars(a)
  const pinyinB = pinyin.getFullChars(b)
  if (pinyinA < pinyinB) {
    return -1
  } else if (pinyinA > pinyinB) {
    return 1
  } else {
    return 0
  }
}
