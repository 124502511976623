<template>
  <div class="no-data-component" :style="{height: `${height}px`}">
    <slot>
      <div class="nodata-search-box"></div>
    </slot>
    <div class="title">{{title}}</div>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  data () {
    return {

    }
  },
  props: {
    title: {
      type: String,
      default: '暂无数据'
    },
    height: {
      type: Number,
      default: 480
    }
  }
}
</script>

<style scoped lang="less">
.no-data-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #31394C;
  margin-top: 30px;
  user-select: none;
  .title {
    font-size: 15px;
    color: #A4B1D6;
    margin-top: 15px;
  }
  .nodata-search-box {
    width: 156px;
    height: 156px;
    background: url('~@/assets/images/no_data/暂无数据_pic.png') no-repeat center/contain;
  }
}
</style>
