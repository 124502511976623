import { Server as _ } from '@/utils/request'

// 对应接口【数据模块】
class SearchApi extends _ {
  // 公司名模糊查询
  getCompanyList (params) {
    return this.ajax('get', '/zdata/entity/name/match', { params }, false, { cancelToken: true, qs: false })
  }

  // 基金名模糊查询
  getFundList (params) {
    return this.ajax('get', '/zdata/fund/name/match', { params })
  }

  /**
   * GP 投资机构名模糊查询
   * @param {string} params
   */
  getInvestmentList (params) {
    return this.ajax('get', '/zdata/investOrg/name/match', { params })
  }

  /**
   * 搜索工商实体
   * http://zyapi.zerone.com.cn/project/20/interface/api/1091
   * @param {*} name
   * @returns
   */
  searchOrg (name) {
    return this.ajax('get', '/zdata/entity/name/match', { params: { name } })
  }

  /**
   * http://zyapi.zerone.com.cn/project/20/interface/api/2576
   * lp模糊匹配
   * @param {string} name 名称
   */
  searchLp (params) {
    return this.ajax('get', '/zdata/lp/name/match', { params })
  }

  /**
   * 基金管理人名模糊查询
   * @param {string} params
   */
  getFundManagerList (params) {
    return this.ajax('get', '/zdata/fund-manager/name/match', { params })
  }

  /**
   * 搜索实体
   * @param {string} entityName 实体名称
   */
  getSearchAll (entityName) {
    return this.ajax('get', '/zdata/search/all/entityName', { params: { entityName } }, false, { cancelToken: true, qs: false })
  }

  // 单一类型
  getSearchSingle (entityName, entityType) {
    return this.ajax('get', '/zdata/search/entity/entityName', { params: { entityName, entityType } })
  }

  // 项目名模糊匹配
  searchProject (name) {
    return this.ajax('get', '/zdata/company/project-name/match', { params: { name } }, false, { cancelToken: true, qs: false })
  }

  /*
  created by fengjie.liu 2020.06.04
  游客-公司名称模糊匹配
  http://zyapi.zerone.com.cn/project/20/interface/api/1791
  /zuser/visitor/entity/name-match?name=北京
  */
  searchCompanyByVisitor (obj) {
    return this.ajax('get', `/zuser/visitor/entity/name-match?name=${obj.name}`)
  }

  /*
  created by fengjie.liu 2020.08.21
  搜索--根据标签查询项目列表
  http://zyapi.zerone.com.cn/project/20/interface/api/2089
  /zdata/search/project-tag/match?tagName=生物医疗
  */
  getSearchTagName (tagName = '生物医疗') {
    return this.ajax('get', `/zdata/search/project-tag/match?tagName=${tagName}`)
  }

  /*
  created by fengjie.liu 2020.08.22
  搜索--是否是项目标签
  http://zyapi.zerone.com.cn/project/20/interface/api/2087
  /search/project-tag/match-check?tagName=生物
  */
  checkTagName (tagName = '生物医疗') {
    return this.ajax('get', `/zdata/search/project-tag/match-check?tagName=${tagName}`)
  }

  /*
  created by fengjie.liu 2020.08.22
  热门搜索
  http://zyapi.zerone.com.cn/project/20/interface/api/1941
  /common/hot-search
  */
  getHotSearch () {
    return this.ajax('get', '/zuser/common/hot-search')
  }

  /**
   * 页面埋点接口
   * http://zyapi.zerone.com.cn/project/20/interface/api/3536
   * **/
  pv (data) {
    return this.ajax('post', '/zuser/pageView/addView', data)
  }
}

export default new SearchApi()
