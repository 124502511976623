
/*
* created by fengjie.liu 2020.12.29
* 参考设计
* https://segmentfault.com/a/1190000021290514
* https://juejin.cn/post/6844904168277147661#comment
* https://www.hansuku.com/archives/158
* fix http://zentao.zerone.com.cn/bug-view-1642.html
* 输入框取消上次请求
* 暂时不能用在全局,目前单独处理接口
* http://localhost:8080/sale?r=1609234298045
* http://localhost:8080/equity/create
* import { removeResolvedFunc, judgePendingFunc } from '@/utils/pendings'
*/
import Axios from 'axios'
import qs from 'qs'
// 声明一个 Map 用于存储每个请求的标识 和 取消函数

const pending = new Map()
const url = (config) => config._qs === false ? [
  config.method,
  config.url
].join('&') : [
  config.method,
  config.url,
  qs.stringify(config.params),
  qs.stringify(config.data)
].join('&')

// 判断请求是否在队列中，如果在就对队列中的该请求执行取消操作
export function judgePendingFunc (config) {
  if (pending.has(url(config))) {
    pending.get(url(config))()
  }
  // 将pending队列中的请求设置为当前
  config.cancelToken = new Axios.CancelToken(cb => {
    pending.set(url(config), cb)
  })
}

// 删除队列中对应已执行的请求
export function removeResolvedFunc (config) {
  if (pending.has(url(config))) {
    pending.delete(url(config))
  }
}

// 清空所有pending请求
export function clearPending () {
  for (const [url, cancel] of pending) {
    cancel(url)
  }
  pending.clear()
}
