<template>
  <div class="empty-module" :style="{ height: height }">
    <div class="inner">
      <div class="icon-box">
        <i class="iconfont" :class="icon"></i>
      </div>
      <span class="tip-text">
        <slot>暂无数据</slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyModule',
  props: {
    height: {
      type: String,
      default: '200px'
    },
    icon: {
      type: String,
      default: 'iconmeiyoushuju_icon'
    }
  }
}
</script>

<style lang="less" scoped>
.empty-module {
  background-color: @bg-color-module;
  display: flex;
  align-items: center;

  .inner {
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: rgba(164, 177, 214, 0.6);

    .icon-box {
      flex-grow: 1;
      font-size: 24px;
      line-height: 1;
      margin-bottom: 12px;

      .iconmeiyoushuju_icon {
        font-size: 24px;
        color: #75809e;
      }
    }

    .tip-text {
      display: block;
      // margin-top: 15px;
    }
  }
}
</style>
