<template>
  <span :class="className" @click="handleClick"><slot></slot></span>
</template>

<script>
export default {
  name: 'LinkTab',
  props: {
    to: {
      type: Object,
      required: true
    },
    className: {
      type: String,
      default: 'link'
    }
  },
  methods: {
    handleClick () {
      this.$open2(this.$router.resolve(this.to))
    }
  }
}
</script>

<style lang="less" scoped>
.link {
  cursor: pointer;
}
</style>
