/**
 * 处理跳转到 ZERONE 的逻辑
 */
export default {
  getHome () {
    return `${process.env.VUE_APP_ZERONE}?utm_source=${process.env.VUE_APP_PRODUCT}`
  },
  getEntityLink(entityType, entityId) {
    return `${process.env.VUE_APP_ZERONE}/info/${entityType}/${entityId}?utm_source=${process.env.VUE_APP_PRODUCT}`
  }
}
