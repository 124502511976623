import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { debouncePv } from '@/utils/pv'
import { recordInvitationLink } from "@/utils/invite"
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

// 避免未登录闪烁进入登录页
const handleBeforeEnter = (to, from, next)=>{
  const token = store.state.token || window.localStorage.getItem('token')
  if (!token) {
    recordInvitationLink(window.location.href)
    next({ name: 'login' })
  }else{
    next()
  }
}


// 维护中
const updating = false

const routesList = [
  {
    path: '/',
    component: () => import('@/components/layouts'),
    beforeEnter: handleBeforeEnter,
    children: [
      {
        path: '',
        name: 'home', // 避免之前遗留路由页面报错
        meta: { title: '资产情报', pageName: '资产情报' },
        component: () => import('@/views/sale'),
        redirect: '/sale'
      },
      {
        path: '/sale',
        name: 'sale',
        meta: { title: '资产情报', pageName: '资产情报' },
        component: () => import('@/views/sale')
      },
      {
        path: '/trade',
        name: 'trade',
        meta: { title: '', pageName: '交易动态' },
        component: () => import('@/views/trade')
      },
      {
        path: '/undeal',
        name: 'undeal',
        meta: { title: '待处理', pageName: '待处理' },
        component: () => import('@/views/undeal')
      },
      {
        // 新建项目股份标的
        path: '/equity/create',
        name: 'create-equity',
        meta: { title: '' },
        component: () => import('@/views/equity/project')
      },
      {
        // 新建基金份额转让标的
        path: '/equity/createfund',
        name: 'create-equity-fund',
        meta: { title: '' },
        component: () => import('@/views/equity/createfund')
      },
      {
        // 新建募集基金转让标的
        path: '/equity/createfundRaise',
        name: 'create-equity-raise',
        meta: { title: '' },
        component: () => import('@/views/equity/createfundRaise')
      },
      {
        // 工作台概览
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard')
      },
      {
        path: '/member',
        meta: { title: '用户管理' },
        component: () => import('@/views/user/member/layouts'),
        children: [
          {
            path: '',
            name: 'member',
            meta: { title: '用户管理', pageName: '用户中心-用户管理' },
            component: () => import('@/views/user/member')
          },
          {
            path: 'invitation/:id',
            name: 'member-invitation',
            meta: { title: '邀请用户', pageName: '用户中心-用户管理-邀请用户' },
            props: route => ({ id: route.params.id }),
            component: () => import('@/views/user/member/invitation')
          },
          {
            path: 'assets/:id',
            name: 'member-assets',
            meta: { title: '用户管理', pageName: '用户中心-用户管理-用户参与标的' },
            props: route => ({ id: route.params.id }),
            component: () => import('@/views/user/member/assets')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录' },
    component: () => import(/* webpackChunkName: "login" */ '../views/login/auth.vue'),
    beforeEnter: (to, from, next) => {
      // 当前已经登录进入login路由跳转到home
      const token = store.state.token || window.localStorage.getItem('token')
      if (token) {
        next({ name: 'home' })
      } else {
        next()
      }
    }
  },
  {
    path: '/user',
    name: 'user',
    meta: { title: '' },
    component: () => import('@/components/layouts'),
    beforeEnter: handleBeforeEnter,
    redirect: '/user/profile',
    children: [
      {
        path: '/user/settings',
        name: 'user-settings',
        meta: { title: '个人中心-机构设置' },
        component: () => import('@/views/user/settings')
      },
      {
        path: '/user/profile',
        name: 'user-profile',
        meta: { title: '个人中心-个人设置' },
        component: () => import('@/views/user/profile')
      },
      {
        path: '/user/profile-form',
        name: 'user-profile-form',
        meta: { title: '个人中心-个人设置-编辑' },
        component: () => import('@/views/user/profile-form')
      }
    ]
  },
  {
    path: '/deal',
    title: '交易管理',
    name: 'deal',
    component: () => import('@/components/layouts'),
    beforeEnter: handleBeforeEnter,
    redirect: '/deal/fund',
    children: [
      {
        path: 'apply',
        name: 'deal-apply',
        meta: { title: '资产情报-申请进度', pageName: '资产情报-申请进度' },
        component: () => import('@/views/deal/apply')
      },
      // 基金份额
      {
        path: 'fund',
        name: 'deal-fund',
        meta: { title: '交易管理-基金份额', pageName: '交易管理-基金份额' },
        component: () => import('@/views/deal')
      },
      // 项目股权
      {
        path: 'project',
        name: 'deal-project',
        meta: { title: '交易管理-项目股权', pageName: '交易管理-项目股权' },
        component: () => import('@/views/deal')
      },
      // 基金募集
      {
        path: 'raise',
        name: 'deal-raise',
        meta: { title: '交易管理-基金募集', pageName: '交易管理-基金募集' },
        component: () => import('@/views/deal')
      },
      // 项目股权/基金份额详情
      {
        path: 'item',
        name: 'deal-item',
        meta: { title: '', pageName: '交易管理-项目股权/基金份额详情' },
        component: () => import('@/views/deal/detail')
      },
      // 项目股权/基金份额尽职调查
      {
        path: 'investigation',
        name: 'deal-investigation',
        meta: { title: '', pageName: '交易管理-项目股权/基金份额尽职调查' },
        component: () => import('@/views/deal/investigation')
      }
    ]
  },
  // 资产管理
  {
    path: '/assets',
    meta: { title: '资产管理' },
    name: 'assets',
    component: () => import('@/components/layouts'),
    beforeEnter: handleBeforeEnter,
    redirect: '/assets/fund',
    children: [
      // 基金份额
      {
        path: 'fund',
        name: 'assets-fund',
        meta: { title: '基金份额', pageName: '资产管理-基金份额' },
        component: () => import('@/views/assets/fund/index')
      },
      {
        path: 'fund/:id',
        name: 'assets-fund-detail',
        meta: { title: '', pageName: '资产管理-基金份额-全部设置' },
        component: () => import('@/views/assets/components/AssetDetail'),
        props: (route) => ({ id: route.params.id })
      },
      {
        path: 'fund/analysis',
        meta: { title: '', pageName: '资产管理-基金详情' },
        props: (route) => ({ id: route.params.id }),
        component: () => import('@/views/assets/components/AssetAnalysis'),
        children: [
          {
            path: 'summary/:id',
            name: 'assets-fund-summary',
            meta: { title: '', pageName: '资产管理-基金概览' },
            props: (route) => ({ id: route.params.id }),
            component: () => import('@/views/assets/fund/analysis/summary')
          },
          {
            path: 'partner/:id',
            name: 'assets-fund-partner',
            meta: { title: '', pageName: '资产管理-基金-合伙人信息' },
            props: (route) => ({ id: route.params.id }),
            component: () => import('@/views/assets/fund/analysis/partner')
          },
          {
            path: 'investment/:id',
            name: 'assets-fund-investment',
            meta: { title: '', pageName: '资产管理-基金-投资信息' },
            props: (route) => ({ id: route.params.id }),
            component: () => import('@/views/assets/fund/analysis/investment')
          },
          {
            path: 'project/:id',
            name: 'assets-fund-project',
            meta: { title: '', pageName: '资产管理-基金-重点项目' },
            props: (route) => ({ id: route.params.id }),
            component: () => import('@/views/assets/fund/analysis/project')
          },
          {
            path: 'document/:id',
            name: 'assets-fund-document',
            meta: { title: '', pageName: '资产管理-基金-更多资料' },
            props: (route) => ({ id: route.params.id }),
            component: () => import('@/views/assets/components/common/document')
          },
          {
            path: 'valuation/:id',
            name: 'assets-fund-valuation',
            meta: { title: '', pageName: '资产管理-基金-基金估值' },
            props: (route) => ({ id: route.params.id }),
            component: () => import('@/views/assets/fund/analysis/valuation')
          }
        ]
      },
      // 项目股权
      {
        path: 'project',
        name: 'assets-project',
        meta: { title: '项目股权', pageName: '资产管理-项目股权' },
        component: () => import('@/views/assets/project/index')
      },
      {
        path: 'project/:id',
        name: 'assets-project-detail',
        props: (route) => ({ id: route.params.id }),
        meta: { title: '', pageName: '资产管理-项目股权-全部设置' },
        component: () => import('@/views/assets/components/AssetDetail')
      },
      {
        path: 'project/analysis',
        meta: { title: '', pageName: '资产管理-项目股权情' },
        props: (route) => ({ id: route.params.id }),
        component: () => import('@/views/assets/components/AssetAnalysis'),
        children: [
          {
            path: 'summary/:id',
            name: 'assets-project-summary',
            props: (route) => ({ id: route.params.id }),
            meta: { title: '', pageName: '资产管理-项目股权-项目概览' },
            component: () => import('@/views/assets/project/analysis/summary')
          },
          {
            path: 'partner/:id',
            name: 'assets-project-partner',
            props: (route) => ({ id: route.params.id }),
            meta: { title: '', pageName: '资产管理-项目股权-股东信息' },
            component: () => import('@/views/assets/project/analysis/partner')
          },
          {
            path: 'history/:id',
            name: 'assets-project-history',
            props: (route) => ({ id: route.params.id }),
            meta: { title: '', pageName: '资产管理-项目股权-融资历史' },
            component: () => import('@/views/assets/project/analysis/history')
          },
          {
            path: 'industry/:id',
            name: 'assets-project-industry',
            meta: { title: '', pageName: '资产管理-项目股权-行业分析' },
            props: (route) => ({ id: route.params.id }),
            component: () => import('@/views/assets/project/analysis/industry')
          },
          {
            path: 'news/:id',
            meta: { title: '', pageName: '资产管理-项目股权-相关新闻' },
            name: 'assets-project-news',
            props: (route) => ({ id: route.params.id }),
            component: () => import('@/views/assets/project/analysis/news')
          },
          {
            path: 'document/:id',
            name: 'assets-project-document',
            meta: { title: '', pageName: '资产管理-项目股权-更多资料' },
            props: (route) => ({ id: route.params.id }),
            component: () => import('@/views/assets/components/common/document')
          }
        ]
      },
      // 募集
      {
        path: 'raise',
        name: 'assets-raise',
        meta: { title: '基金募集', pageName: '资产管理-基金募集' },
        component: () => import('@/views/assets/raise/index')
      },
      {
        path: 'raise/:id',
        name: 'assets-raise-detail',
        meta: { title: '', pageName: '资产管理-基金募集-全部设置' },
        component: () => import('@/views/assets/components/AssetDetail'),
        props: (route) => ({ id: route.params.id })
      },
      {
        path: 'raise/analysis',
        meta: { title: '', pageName: '资产管理-基金募集详情' },
        props: (route) => ({ id: route.params.id }),
        component: () => import('@/views/assets/components/AssetAnalysis'),
        children: [
          {
            path: 'summary/:id',
            name: 'assets-raise-summary',
            meta: { title: '', pageName: '资产管理-基金募集-募集概览' },
            props: (route) => ({ id: route.params.id }),
            component: () => import('@/views/assets/raise/analysis/summary')
          },
          {
            path: 'team/:id',
            name: 'assets-raise-team',
            meta: { title: '', pageName: '资产管理-基金募集-管理团队' },
            props: (route) => ({ id: route.params.id }),
            component: () => import('@/views/assets/raise/analysis/team')
          },
          {
            path: 'investment/:id',
            name: 'assets-raise-investment',
            props: (route) => ({ id: route.params.id }),
            meta: { title: '', pageName: '资产管理-基金募集-投资逻辑' },
            component: () => import('@/views/assets/raise/analysis/investment')
          },
          {
            path: 'history/:id',
            name: 'assets-raise-history',
            props: (route) => ({ id: route.params.id }),
            meta: { title: '', pageName: '资产管理-基金募集-历史业绩' },
            component: () => import('@/views/assets/raise/analysis/history')
          },
          {
            path: 'document/:id',
            name: 'assets-raise-document',
            props: (route) => ({ id: route.params.id }),
            meta: { title: '', pageName: '资产管理-基金募集-更多资料' },
            component: () => import('@/views/assets/components/common/document')
          }
        ]
      },
      {
        path: 'approval/:id',
        name: 'assets-approval',
        component: () => import('@/views/assets/approval'),
        meta: { title: '', pageName: '资产管理- 项目股权-审批' },
        props: (route) => ({ id: route.params.id })
      },
      {
        path: 'opponent/:id',
        name: 'assets-opponent',
        component: () => import('@/views/assets/opponent'),
        meta: { title: '', pageName: '资产管理-交易对手' },
        props: (route) => ({ id: route.params.id })
      },
      {
        path: 'notes/:id',
        name: 'assets-notes',
        component: () => import('@/views/assets/notes'),
        meta: { title: '', pageName: '资产管理-笔记' },
        props: (route) => ({ id: route.params.id })
      },
      {
        path: 'statics/:id',
        name: 'assets-statics',
        component: () => import('@/views/assets/statics'),
        meta: { title: '', pageName: '资产管理-统计' },
        props: (route) => ({ id: route.params.id })
      },
      {
        path: 'deleted',
        name: 'assets-deleted',
        component: () => import('@/views/assets/deleted')
      }
    ]
  },
  {
    path: '/documents',
    title: 'Z盘',
    name: 'documents',
    component: () => import('@/components/layouts'),
    beforeEnter: handleBeforeEnter,
    redirect: '/documents/folders',
    children: [
      // 我的资料库列表
      {
        path: '/documents/folders',
        name: 'documents-lists',
        meta: { title: '机构资料库', pageName: 'Z盘-机构资料库' },
        component: () => import('@/views/documents/components/common/lists')
      },
      // 我的资料库文件夹
      {
        path: '/documents/folder',
        name: 'documents-lists-item',
        props: function (route) {
          const { folderId, parentFolderId, parentFolderName } = route.query
          return { folderId, parentFolderId, parentFolderName }
        },
        meta: { title: '', pageName: 'Z盘-我的资料库文件夹' },
        component: () => import('@/views/documents/components/common/lists-item')
      },
      // 资料交换室
      {
        path: '/documents/exchange',
        name: 'documents-exchange',
        meta: { title: '资料交换室', pageName: 'Z盘-资料交换室' },
        component: () => import('@/views/documents/components/common/exchange')
      },
      // 资料交换室详情/资料交换室文件夾详情
      {
        path: '/documents/exchange-item',
        name: 'documents-exchange-item',
        props: function (route) {
          const { folderId, parentFolderId, parentFolderName } = route.query
          return { folderId, parentFolderId, parentFolderName }
        },
        meta: { title: '', pageName: 'Z盘-资料交换室详情/资料交换室文件夾详情' },
        component: () => import('@/views/documents/components/common/exchange-item')
      }
    ]
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/static/agreement')
  },
  {
    path: '/authorize',
    name: 'authorize',
    component: () => import('@/views/static/authorize')
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('@/views/static/vipVersion')
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('@/views/static/apply')
  },
  {
    // 执中用隐私政策
    path: '/privacy-policy',
    name: 'policy',
    component: () => import('@/views/static/policy')
  },
  {
    // 执中用户协议
    path: '/terms-of-use',
    name: 'protocol',
    component: () => import('@/views/static/protocol')
  },
  {
    path: '/permission-denied',
    name: 'permission-denied',
    meta: { title: '无权访问', pageName: '403' },
    component: () => import('@/views/static/403')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/static/404')
  }
]

const routes = updating
  ? [{ path: '*', name: 'updating', component: () => import('@/views/static/updating') }]
  : routesList

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  /**
   * title 用于展示；pageName 用于自己的统计；
   * 神策埋点统计优先使用 title，如果没有 title 则使用 pageName；最后用 【执中ZDEAL】补位
   */
  window.document.title = to.meta.title || to.meta.pageName || '执中ZDEAL'
  next()
})

router.afterEach(to => {
  // 神策
  window.sensors && window.sensors.quick('autoTrackSinglePage')

  // 记录 PV
  const token = store.state.token || window.localStorage.getItem('token')
  if (token) {
    const { name } = to
    const mapPageId = {
      home: 10002,
      sale: 10002,
      trade: 10003,
      undeal: 10004,
      'create-equity': 10008,
      'create-equity-fund': 10009,
      'create-equity-raise': 10010,
      'member': 10017,
      'member-invitation': 10018,
      'member-assets': 10019,
      'user-profile': 10022,
      'user-profile-form': 10023,
      'user-settings': 10024,
      'deal-fund': 10027,
      'deal-project': 10028,
      'deal-raise': 10029,
      'deal-item': 10030,
      'deal-investigation': 10031,
      'assets-fund': 10032,
      'assets-fund-detail': 10033,
      'assets-fund-summary': 10034,
      'assets-fund-partner': 10035,
      'assets-fund-investment': 10036,
      'assets-fund-project': 10037,
      'assets-fund-document': 10038,
      'assets-fund-valuation': 10039,
      'assets-project': 10040,
      'assets-project-detail': 10041,
      'assets-project-summary': 10042,
      'assets-project-partner': 10043,
      'assets-project-history': 10044,
      'assets-project-industry': 10045,
      'assets-project-news': 10046,
      'assets-project-document': 10047,
      'assets-raise': 10048,
      'assets-raise-detail': 10049,
      'assets-raise-summary': 10050,
      'assets-raise-team': 10051,
      'assets-raise-investment': 10052,
      'assets-raise-history': 10053,
      'assets-raise-document': 10054,
      'assets-approval': 10055,
      'assets-opponent': 10056,
      'assets-notes': 10057,
      'assets-statics': 10058,
      'assets-deleted': 10059,
      'documents-lists': 10060,
      'documents-lists-item': 10061,
      'documents-exchange': 10062,
      'documents-exchange-item': 10063,
      agreement: 10077,
      authorize: 10078,
      vip: 10079,
      apply: 10080,
      policy: 10081,
      protocol: 10082,
      'permission-denied': 10083,
      404: 10084,
      login: 10091,
      'org-shareholder': 10092, // 实体或者lp共用的股东变更历史列表
      'deal-apply': 10094 // 交易机会申请进度页面
    }[name] || to.meta.pageId
    if (!mapPageId) {
      console.warn('没有mapPageId', to.meta)
      return false
    }
    // 打点统计
    debouncePv({ pageId: mapPageId })
  }
})

/* 路由异常错误处理，尝试解析一个异步组件时发生错误，重新渲染目标页面 */
router.onError(error => {
  console.error('router.error.message', error.message)
  const pattern = /Loading chunk (\S)+ failed./g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    console.log('版本已更新，重新加载:', targetPath)
    // router.replace(targetPath)
    window.location.reload()
  }
})

export default router
