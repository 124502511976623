import { Server as _ } from '@/utils/request'

class NdaApi extends _ {
  // get nda info
  getNDAInfo (dealId) {
    return this.ajax('get', '/zasset/deal/nda/info', { params: { dealId } })
  }

  // 创建
  createNAD (params) {
    return this.ajax('post', '/zasset/deal/nda/create', params)
  }

  // clear 清除
  ndaClear (dealId) {
    return this.ajax('get', '/zasset/deal/nda/clear', { params: { dealId } })
  }

  // 是否接受
  isAccept (params) {
    return this.ajax('post', '/zasset/deal/nda/update', params)
  }
}

export default new NdaApi()
