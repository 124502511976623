import Vue from 'vue'
import Vuex from 'vuex'
import { searchFilterLabel } from '@/utils/constData'
import userApi from '@/api/user2'
import login from './modules/login'
import home from './modules/home'
import layout from './modules/layout'
import buyer from './modules/buyer'
import nda from './modules/nda'
import asset from './modules/asset'

Vue.use(Vuex)

/**
 * 用户信息暂存，参考：https://www.cnblogs.com/yaya-003/p/12750819.html
 */
export default new Vuex.Store({
  state: {
    token: '',
    logout: '', // 记录注销状态，一次性，传递给统一登录完成退出
    mobile: '',
    loading: false, // 整个页面的loading
    cardLoading: false, // 右侧是菜单，左侧是内容的局部loading
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
    searchDetailData: [],
    searchDetailType: '',
    searchStr: '',
    searchTagIndex: '全部',
    searchRe: {
      gpList: {
        count: 0,
        dataList: []
      },
      fundList: {
        count: 0,
        dataList: []
      },
      companyList: {
        count: 0,
        dataList: []
      },
      lpList: {
        count: 0,
        dataList: []
      },
      fundManagerList: {
        count: 0,
        dataList: []
      }
    },
    getTitleData: {
      total: 0,
      arr: []
    },
    currency: '1', // 资产列表页，币种，默认人民币
    // 项目估值
    evaluationInfo: JSON.parse(localStorage.getItem('evaluationInfo')) || {},
    // 4个估值模块标识保存并分享-不要动，坑很多-修改需要4个模块一一验证
    storeValuation: {
      COMPARE_LISTED_COMPANY: {
        triggerSave: 0
      },
      PROJECT_VALUATION: {
        triggerSave: 0
      },
      FUND_VALUATION: {
        triggerSave: 0
      },
      FUND_TRADE_VALUATION: {
        triggerSave: 0,
        test: '2222'
      }
    },
    // 执中智库列表
    referenceFolderList: JSON.parse(localStorage.getItem('referenceFolderList')) || [],
    vipKey: 'zdeal_zdeal'
  },
  getters: {
    appVersion (state) {
      const arrayList = state.vipKey ? state.vipKey.split('_') : []
      return arrayList[1]
    },
    isMaster (state, getters) {
      return getters.appVersion === 'master'
    },
    getTitleData: state => {
      const re = {
        total: 0,
        arr: []
      }
      const ordDictionary = {
        gpList: {
          name: '投资机构',
          keyName: 'gpNameCn',
          type: 'GP'
        },
        fundList: {
          name: '基金',
          keyName: 'fundName',
          type: 'FUND'
        },
        companyList: {
          name: '项目',
          keyName: 'companyName',
          type: 'COMPANY'
        },
        lpList: {
          name: 'LP',
          keyName: 'lpName',
          type: 'LP'
        },
        fundManagerList: {
          name: '基金管理人',
          keyName: 'fundManager',
          type: 'FUND_MANAGER'
        }
      }
      // 搜索结果页搜索结果，有一定的排序规则
      const mapKeys = searchFilterLabel
      for (const i of mapKeys) {
        re.total += state.searchRe[i].count
        if (state.searchRe[i].count) {
          re.arr.push({ name: ordDictionary[i].name, type: ordDictionary[i].type, count: state.searchRe[i].count })
        }
      }
      if (re.total) {
        re.arr.unshift({ name: '全部', count: re.total })
      }
      return re
    },
    // 导航搜索结果，有一定的排序规则
    searchResult: state => {
      return {
        labelProject: state.searchRe.labelProject,
        gpList: state.searchRe.gpList,
        fundList: state.searchRe.fundList,
        companyList: state.searchRe.companyList,
        lpList: state.searchRe.lpList,
        fundManagerList: state.searchRe.fundManagerList
      }
    },
    // loading 状态
    loading: state => state.loading,
    storeValuation: state => state.storeValuation
  },
  mutations: {
    setLoading (state, loading) {
      console.log('setLoading', loading)
      state.loading = loading
    },
    changeState (state, payload = {}) {
      if (Object.prototype.hasOwnProperty.call(payload, 'key') && Object.prototype.hasOwnProperty.call(payload, 'value')) {
        if (state[payload.key] === undefined) {
          console.error('invalid field for state!')
        }
        if (payload.localStorage) {
          // 将传递的数据保存到localStorage中
          localStorage.setItem(payload.key, typeof (payload.value) === 'object' ? JSON.stringify(payload.value) : payload.value)
        }
        // console.log(`store 更改 ${payload.key}`, payload.value)
        state[payload.key] = payload.value
      } else {
        console.error('invalid parameters!')
      }
    }
  },
  actions: {
    getUserInfo ({ commit }) {
      userApi.getUserInfo()
        .then(function (data) {
          commit('changeState', { key: 'userInfo', value: data, localStorage: true })
        })
        .catch(function (err) {
          console.error(err)
        })
    }
  },
  modules: {
    login,
    home,
    layout,
    buyer,
    nda,
    asset
  }
})
