// import { recordInvitationLink } from "@/utils/invite"
const cackeKey = 'unauthorized'

export function getInvitationLink () {
  return window.sessionStorage.getItem(cackeKey)
}

export function deleteInvitationLink () {
  console.log('删除 unauthorized invite')
  window.sessionStorage.removeItem(cackeKey)
}

export function recordInvitationLink (url) {
  const record = url.indexOf('login') === -1
  if (record) {
    console.log('记录访问链接', url)
    window.sessionStorage.setItem(cackeKey, url)
  }
}
