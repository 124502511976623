<template>
  <a-modal
    v-model="visible"
    width="auto"
    title=""
    centered
    :footer="null"
    :closable="false"
    :maskClosable="false"
    :zIndex="1002"
    :bodyStyle="{ padding: '20px' }"
  >
    <div class="message-content">
      <svgIcon :icon="icon" :zStyle="{ width: '21px', height: '21px' }" />
      <span class="text">{{ text }}</span>
    </div>
  </a-modal>
</template>

<script>
import svgIcon from './svgIcon'

export default {
  name: 'Message',
  components: { svgIcon },
  data () {
    return {
      type: '',
      visible: false,
      text: ''
    }
  },
  computed: {
    icon: function () {
      return {
        success: '#iconsuccess',
        error: '#iconfail',
        warning: '#iconwarning'
      }[this.type]
    }
  }
}
</script>

<style lang="less" scoped>
.message-content {
  display: flex;
  justify-content: center;
  align-items: center;
  .svg-icon {
    vertical-align: middle;
  }
  .text {
    margin-left: 10px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.85);
  }
}
</style>
