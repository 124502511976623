// import scrollTo from 'ant-design-vue/es/_util/scrollTo'
import Vue from 'vue'
import {
  Button,
  Form,
  FormModel,
  Input,
  InputNumber,
  Spin,
  Modal,
  Table,
  Select,
  Popover,
  Radio,
  Dropdown,
  Icon,
  Menu,
  Row,
  Col,
  Tag,
  AutoComplete,
  Checkbox,
  Message,
  Notification,
  Upload,
  Steps,
  Tooltip,
  Pagination,
  ConfigProvider,
  Empty,
  Progress,
  DatePicker,
  Tabs,
  Tree,
  Slider,
  Collapse,
  Card,
  List,
  Affix,
  Carousel,
  Layout,
  Timeline,
  BackTop,
  Cascader
} from 'ant-design-vue'

Vue.component(Button.name, Button)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Spin)
Vue.use(Modal)
Vue.use(Table)
Vue.use(Select)
Vue.use(Popover)
Vue.use(Radio)
Vue.use(Dropdown)
Vue.use(Icon)
Vue.use(Menu)
Vue.use(Row)
Vue.use(Col)
Vue.use(Tag)
Vue.use(AutoComplete)
Vue.use(Checkbox)
Vue.use(Upload)
Vue.use(Steps)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(ConfigProvider)
Vue.use(Empty)
Vue.use(Progress)
Vue.use(DatePicker)
Vue.use(Tabs)
Vue.use(Tree)
Vue.use(Slider)
Vue.use(Collapse)
Vue.use(Card)
Vue.use(List)
Vue.use(Affix)
Vue.use(Layout)
Vue.use(Carousel)
Vue.use(Timeline)
Vue.use(BackTop)
Vue.use(Cascader)

Vue.prototype.$message = Message
Vue.prototype.$message.config({
  duration: 3
})
Vue.prototype.$notification = Notification
