import { Server as _ } from '@/utils/request'
class BuyerApi extends _ {
  getInfo (params) {
    return this.ajax('get', '/zasset/authenticate/buyer/info', { params })
  }

  submitInfo (params) {
    return this.ajax('post', '/zasset/authenticate/buyer/submit', params)
  }

  updateInfo (params) {
    return this.ajax('post', '/zasset/authenticate/buyer/edit', params)
  }

  clearInfo (params) {
    return this.ajax('get', '/zasset/authenticate/buyer/clear', { params })
  }

  getAssets (params) {
    return this.ajax('get', '/zasset/deal/buyer-deal/list/s-fund', { params })
  }

  getAssetsP (params) {
    return this.ajax('get', '/zasset/deal/buyer-deal/list/project', { params })
  }

  getDealInfo (dealId) {
    return this.ajax('get', '/zasset/deal/buyer-deal/general-info', { params: { dealId } })
  }

  // 买家尽调列表
  getInvestList (dealId) {
    return this.ajax('get', '/zasset/deal/buyer-deal/due-diligence/list', { params: { dealId } })
  }

  // 买家发送尽调
  sendInvestInfo (params) {
    return this.ajax('post', '/zasset/deal/buyer-deal/due-diligence/create', params)
  }

  // 跟进
  doCheckMore (params) {
    return this.ajax('post', '/zasset/deal/buyer-deal/match-pass', params)
  }

  // 拉取消息状态
  getNewsList (dealId) {
    return this.ajax('get', '/zasset/deal/buyer-deal/due-diligence/list', { params: { dealId } })
  }

  // 买家终止交易
  stopDeal (params) {
    return this.ajax('post', '/zasset/deal/buyer-deal/stop-deal', params)
  }

  /*
  created by fengjie.liu 2020.07.16
  买家-募资deal列表
  http://zyapi.zerone.com.cn/project/20/interface/api/1971
  /zasset/deal/buyer-deal/list/fundraising
  */
  getBuyerFundraising (params) {
    return this.ajax('get', '/zasset/deal/buyer-deal/list/fundraising', params)
  }
}

export default new BuyerApi()
