const state = {
}
const mutations = {
  changeState (state, payload = {}) {
    // eslint-disable-next-line no-prototype-builtins
    if (payload.hasOwnProperty('key') && payload.hasOwnProperty('value')) {
      if (state[payload.key] === undefined) {
        console.error('invalid field for state!')
      }
      state[payload.key] = payload.value
    } else {
      console.error('invalid parameters!')
    }
  }
}
const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
