import numeral from 'numeral'
import { numberWithCommas as wiliamNumberWithCommas } from 'wiliam/lib/formatter'
import { formatMoneyAuto2 } from '@/filters'
import { excludeZeroIsFalse } from '@/utils/libs'

export const numberWithCommas = wiliamNumberWithCommas

// 常用单位列表： 万、千万、亿、百亿、千亿、万亿
const wan = 10000
const baiwan = 100 * wan
const qianwan = 1000 * wan
const yi = wan * wan
const qianyi = 1000 * yi
const baiyi = 100 * yi
const wanyi = wan * yi

export function formatPhoneNumber (phoneNumber) {
  if (phoneNumber.length !== 11) {
    console.warn('formatPhoneNumber 手机号长度不是 11 位')
    return phoneNumber
  }
  let res = phoneNumber.slice(0, 3)
  res += ' '
  res += phoneNumber.slice(3, 7)
  res += ' '
  res += phoneNumber.slice(7)
  return res
}

export function formatIdentityNumber (identityNumber) {
  if (identityNumber.length !== 18) {
    console.warn('formatIdentityNumber 身份证号长度不是 18 位')
    return identityNumber
  }

  let res = identityNumber.slice(0, 6)
  res += ' '
  res += identityNumber.slice(6, 14)
  res += ' '
  res += identityNumber.slice(14)
  return res
}

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize (time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + '前'
}

/**
 * @param {number} time
 */
export function timeAgo (time) {
  // 取绝对值，防止数据库时区导致的未来时间看上去奇怪
  const between = Math.abs(Date.now() / 1000 - Number(time))
  if (between < 3600) {
    return pluralize(~~(between / 60), '分钟')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), '小时')
  } else {
    return pluralize(~~(between / 86400), '天')
  }
}

/**
 * 将数字转为 亿 单位
 * @param {number} amount
 */
export function toBillion (amount) {
  return amount / 1e8
}

/**
 * 将时间戳格式化为 yyyy-MM-dd
 * @param {number} timestamp
 */
export function toYMD (timestamp) {
  const time = new Date(timestamp * 1000)
  var str = time.getFullYear() + '-' +
      ('00' + (time.getMonth() + 1)).slice(-2) + '-' +
      ('00' + time.getDate()).slice(-2)
  return str
}

/**
 * update 这个少用，需要废弃，建议使用|timestamp2date或者|timestamp2time
 * 时间格式转化函数
 * @param {fmt} 输出格式
 * @param {date} 时间戳
 * dateFormat("YYYY-mm-dd HH:MM:SS", date)
 */
export function dateFormat (fmt, date) {
  let ret
  /* 后端10位时间戳 */
  if (!date) return '-'
  date = new Date(date * 1000)
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    };
  };
  return fmt
}

/**
 * update by fengjie.liu 2020.08.26 不区分大小写
 *  https://stackoverflow.com/questions/42126652/javascript-case-insensitive-string-replace/42126742
 */
export function changeReInfo (...args) {
  const temArr = Array.from(args)
  const searchStr = temArr.pop()
  let totalName = temArr.shift()
  if (temArr.length) {
    for (const i of temArr) {
      if (i) {
        /*
        待处理---搜索中国石化，code码显示中文还是英文
        created by fengjie.liu 2021.0518
         */
        // totalName = `${totalName} (${Array.isArray(i) ? i.join('，') : i})`
        totalName = `${totalName} (${i})`
        break
      }
    }
  }
  return totalName && totalName.replace(new RegExp('(' + searchStr + ')', 'gi'), '<span style="color: #FF6F64;">$1</span>')
  // return totalName && totalName.split(searchStr).join(`<span style="color: #FF6F64;">${searchStr}</span>`)
}

export function formatPercentNumber (input) {
  if (!input) return input
  // console.log('input:', input)
  var list = input.split('.')
  var a = list[0]
  if (!list[1]) return a.split('%')[0] + '.00' + '%'
  var b = list[1].split('%')
  var c = b[0]
  var d = c.split('').reverse()

  var index = d.findIndex(function (i) {
    return i !== '0'
  })

  var e = d.slice(index).reverse().join('')
  if (e.length === 1) e += '0'

  var output = a + '.' + e + '%'
  // console.log('output', output)
  return output
}

/**
 * 数量，个数格式化
 * @param {*} num
 * @param {*} pattern
 * @returns
 */
export function count (num, pattern = '0,0') {
  return num !== null ? numeral(num).format(pattern) : '-'
}

export const formatWan = (num, pattern = '0,0.00') => {
  return num !== null ? numeral(num / wan).format(pattern) : '-'
}

export const formatYi = (num, pattern = '0,0.00') => {
  return num !== null ? numeral(num / yi).format(pattern) : '-'
}

export const parseNum = (num) => {
  if (num >= wanyi) {
    return {
      unit: '万亿',
      value: num / wanyi
    }
  } else if (num >= qianyi) {
    return {
      unit: '千亿',
      value: num / qianyi
    }
  } else if (num >= baiyi) {
    return {
      unit: '百亿',
      value: num / baiyi
    }
  } else if (num >= yi) {
    return {
      unit: '亿',
      value: num / yi
    }
  } else if (num >= qianwan) {
    return {
      unit: '千万',
      value: num / qianwan
    }
  } else if (num >= wan) {
    return {
      unit: '万',
      value: num / wan
    }
  } else {
    return {
      unit: '',
      value: num
    }
  }
}

/**
 * 数字格式化
 * @param {*} num
 * @param {*} unit
 * @param {*} showUnit
 * @param {*} pattern
 * @returns
 */
export function numeric (num, unit = '', showUnit = false, pattern = '0,0.00') {
  if (excludeZeroIsFalse(num)) return '-'
  let res = ''
  if (unit === '万亿') {
    res = numeral(num / wanyi).format(pattern)
  } else if (unit === '千亿') {
    res = numeral(num / qianyi).format(pattern)
  } else if (unit === '亿') {
    res = numeral(num / yi).format(pattern)
  } else if (unit === '千万') {
    res = numeral(num / qianwan).format(pattern)
  } else if (unit === '百万') {
    res = numeral(num / baiwan).format(pattern)
  } else if (unit === '万') {
    res = numeral(num / wan).format(pattern)
  } else {
    res = numeral(num).format(pattern)
  }
  if (showUnit) res += unit
  return res
}

// update by fengjie.liu 2020.11.24 统一资产管理饼图等页面金融格式化显示（不显示具体单位）
export function formatNumber (num) {
  return formatMoneyAuto2(num, 1000)
}

export function numberFormat (val, pattern = '0.00%') {
  return numeral(val).format(pattern)
}

export function parsePercent (num, pattern = '0.00%') {
  if (num === null) return '-'
  // numeral 接受的最小数字 1e-6
  if (num < 0) {
    return numeral(num).format(pattern)
  }
  if (num < 1e-6) return '0.00%'
  return numeral(num).format(pattern)
}

/**
 * 保留 2位小数
 * @param {*} num
 * @returns
 */
function processNumber (num) {
  // Math.round 并不能解决 9.99 被格式化为 10 的问题
  return Number.parseInt(num * 100) / 100
}

/**
 * 将数字转为自动提升单位的对象
 * @param {*} input
 * @param {Bool} fix true 不进位，修复 9.99 被格式化为 10 的问题； false 进位
 * @param {*} pattern
 * @returns
 */
export function num2obj (input, fix = true, pattern = '0,0.00') {
  if (typeof input !== 'number') return { symbol: '', value: '-', unit: '' }

  let unit = ''
  let value = '-'
  let symbol = ''
  if (input < 0) symbol = '-'

  const num = Math.abs(input)
  if (num >= wanyi) {
    unit = '万亿'
    // processNumber 来修复 9.99 被格式化为 10 的问题
    value = numeral(fix ? processNumber(num / wanyi) : num / wanyi).format(pattern)
  } else if (num >= (yi - 50)) { // 99999950 即 9999.995万，需要展示为 1.00 亿
    unit = '亿'
    value = numeral(fix ? processNumber(num / yi) : num / yi).format(pattern)
  } else if (num >= wan) {
    unit = '万'
    value = numeral(fix ? processNumber(num / wan) : num / wan).format(pattern)
  } else {
    unit = ''
    value = numeral(num).format(pattern)
  }
  return { symbol, value, unit }
}

/**
 * 将数字转为自动提升单位的字符串
 * @param {*} num
 * @param {Bool} fix true 不进位，修复 9.99 被格式化为 10 的问题； false 进位
 * @returns
 */
export function num2text (num, fix = true) {
  const numObj = num2obj(num, fix)
  return numObj.symbol + numObj.value + numObj.unit
}
