/* eslint-disable import/no-duplicates */
import buyerApi from '@/api/buyer'
import sellerApi from '@/api/seller'
import homeApi from '@/api/home'
import store from '@/store'
import mainState from '../index'
const state = {
  capitalListVisible: false,
  detailStatusShow: [
    { buyer: '项目入库(已签署保密协议)', name: '标的准备', isActive: false, isError: false },
    { buyer: '立项跟踪', name: '交易撮合', isActive: false, isError: false },
    { buyer: '尽职调查', name: '尽职调查', isActive: false, isError: false },
    { buyer: '投资决策', name: '报价磋商', isActive: false, isError: false },
    { buyer: '投后管理', name: '交易成交', isActive: false, isError: false }
  ],
  refuseMsg: '',
  opBtnDetail: {
    show: true,
    disable: false,
    text: '发布交易机会',
    action: 'match'
  },
  fundDetail: {},
  msgList: [],
  homeTableList: [],
  dealList: []
}
const mutations = {
  changeState (state, payload = {}) {
    // eslint-disable-next-line no-prototype-builtins
    if (payload.hasOwnProperty('key') && payload.hasOwnProperty('value')) {
      if (state[payload.key] === undefined) {
        console.error('invalid field for state!')
      }
      state[payload.key] = payload.value
    } else {
      console.error('invalid parameters!')
    }
  },
  changeDetailStatusShow (state, dealStatus) {
    function deal (num, isError) {
      // 重置所有状态
      for (let j = 0; j < 5; j++) {
        state.detailStatusShow[j].isActive = false
        state.detailStatusShow[j].isError = false
      }
      for (let i = 0; i <= num; i++) {
        state.detailStatusShow[i].isActive = true
        state.detailStatusShow[i].isError = false
        if (i === num && isError) {
          state.detailStatusShow[i].isActive = false
          state.detailStatusShow[i].isError = true
        }
      }
    }
    console.log(dealStatus, 'store.home.changeDetailStatusShow')
    switch (dealStatus) {
      case 'READY':
        if (state.fundDetail.isStop) { // 标的准备过程中也可以被中止, 后端缺状态
          deal(0, true)
        } else {
          deal(0, false)
        }
        break
      case 'MATCHING':
        if (store.state.userInfo.userType === 'SELLER') {
          deal(1, false)
        } else {
          deal(0, false)
        }
        break
      case 'MATCH_PASS':
        deal(1, false)
        break
      case 'MATCH_REFUSE':
        deal(1, true)
        break
      case 'DUE_DILIGENCE':
        deal(2, false)
        break
      case 'DUE_DILIGENCE_REFUSE':
        deal(2, true)
        break
      case 'OFFER':
        deal(3, false)
        break
      case 'OFFER_REFUSE':
        deal(3, true)
        break
      case 'DEAL_COMPLETE':
        deal(4, false)
        break
      case 'NULL':
        deal(0, false)
        state.detailStatusShow[0].isActive = false
        break
      default:
    }
  },
  updateHomeTableList (state, homeTableList) {
    state.homeTableList = homeTableList
  }
}
const actions = {
  changeBTNDealStatus ({ state }, id) {
    if (mainState.state.userInfo.userType === 'SELLER') { // 卖家
      sellerApi.getDealInfo(id)
        .then(res => {
          state.fundDetail = res
          // 更改step
          mutations.changeDetailStatusShow(state, res.dealStatus)
          // 更改拒绝消息 ?? 这里要删
          if (res.isStop) {
            state.refuseMsg = res.stopComment
          }
          switch (res.matchStatus) {
            case 'NONE':
              if (!res.isApproval) { // 标的准备过程中也可以被中止, 后端缺状态
                state.opBtnDetail = {
                  show: true,
                  disable: true,
                  text: '等待解析',
                  action: 'match-waiting'
                }
              } else {
                state.opBtnDetail = {
                  show: true,
                  disable: false,
                  text: '发布交易机会',
                  action: 'match'
                }
              }
              break
            case 'MATCHING':
              state.opBtnDetail.disable = true
              state.opBtnDetail.text = '交易撮合中…'
              break
            case 'MATCHED':
              state.opBtnDetail.disable = true
              state.opBtnDetail.text = '已对接买方…'
              break
            case 'FOLLOW_UP':
              state.opBtnDetail.disable = false
              state.opBtnDetail.text = '买方已跟进'
              state.opBtnDetail.action = 'show-info'
              break
            case 'REFUSE':
              state.opBtnDetail.disable = false
              state.opBtnDetail.text = '买方已拒绝'
              state.opBtnDetail.action = 'show-info'
              break
          }
          /* 终止特殊处理 */
          if (res.isStop) {
            state.opBtnDetail.show = false
          }
        })
    } else if (mainState.state.userInfo.userType === 'BUYER') {
      buyerApi.getDealInfo(id)
        .then(res => {
          state.fundDetail = res.data
          // 更改step
          // 当卖家切换到买家身份查看自己的交易时，交易状态应该始终为空
          let dealStatus = res.data.dealStatus
          if (mainState.state.userInfo.userId === res.data.sellerUserId) {
            dealStatus = 'NULL'
          }
          console.log('二次更新交易状态条')
          mutations.changeDetailStatusShow(state, dealStatus)
          // 更改拒绝消息
          if (res.data.isStop) {
            state.refuseMsg = res.data.stopComment
          }
          switch (res.data.matchStatus) {
            case 'FOLLOW_UP':
              state.opBtnDetail.disable = true
              state.opBtnDetail.text = '已跟进'
              state.opBtnDetail.action = ''
              break
            case 'MATCHED':
              state.opBtnDetail.disable = false
              state.opBtnDetail.text = '跟进'
              state.opBtnDetail.action = 'check-more'
              break
          }
        })
    }
  },
  getMessagesList ({ state }, id) {
    if (mainState.state.userInfo.userType === 'SELLER') { // 卖家
      sellerApi.sellerInvestNews(id)
        .then(res => {
          if (res) state.msgList = res
        })
    } else if (mainState.state.userInfo.userType === 'BUYER') {
      buyerApi.getInvestList(id)
        .then(res => {
          if (res.data) state.msgList = res.data
        })
    }
  },
  sellerHomeList ({ state }) {
    store.state.loading = true
    homeApi.getCapitalList()
      .then(res => {
        store.state.loading = false
        state.dealList = res
        if (res.length > 6) {
          state.homeTableList = res.slice(0, 6)
          return
        }
        state.homeTableList = res
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
