// http://numeraljs.com/
import numeral from 'numeral'
import moment from 'moment'

/**
 * 推荐使用过滤器
 *
 * 百分比      percent
 * 数字对象    num2text
 * 币种        currency
 * MOC        moc
 * 数量       count
 * 数字       number
 */

import {
  formatNumber as formatNumberSource,
  numberFormat as numberFormatSource,
  formatPercentNumber,
  formatWan,
  parsePercent,
  numeric as numericInput,
  num2text as num2textInput,
  count as countInput
} from '@/utils/format'
import { excludeZeroIsFalse, getFileIcon, fileSize } from '@/utils/libs'

export const formatNumber = formatNumberSource
export const number2wan = formatWan
export const numeric = numericInput
export const numberFormat = numberFormatSource
export const num2text = num2textInput
export const count = countInput

/**
 * MOC 格式化，保留2位小数，后面追加x
 * @param {*} val
 * @returns
 */
export function moc(val) {
  if (excludeZeroIsFalse(val)) {
    return '-'
  }
  return numeral(val).format('0,0.00') + 'x'
}

/**
 * 格式化普通数字
 * @param {*} val
 * @param {*} pattern
 * @returns string
 */
export function number(val, pattern = '0,0.00') {
  if (excludeZeroIsFalse(val)) {
    return '-'
  }
  return numeral(val).format(pattern)
}

export function timestamp(date) {
  let fmt = 'YYYY-mm-dd HH:MM:SS'
  let ret
  /* 后端10位时间戳 */
  if (!date) return '-'
  date = new Date(date * 1000)
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }
  return fmt
}

// 格式化日期
export function formatDate(value, pattern = 'YYYY-MM-DD HH:mm:ss') {
  // 时间戳返回为
  if (!value) {
    return '-'
  }
  return moment(value * 1000).format(pattern)
}
// 使用最多
export function timestamp2date(val, defaultValue = '-') {
  if (!val) return defaultValue
  return moment(val * 1000).format('YYYY-MM-DD')
}

export function timestamp2month(val, defaultValue = '-') {
  if (!val) return defaultValue
  return moment(val * 1000).format('YYYY-MM')
}

export function timestamp2time(val, defaultValue = '-') {
  if (!val) return defaultValue
  return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
}

// 高级筛选日期处理
// fix http://zentao.zerone.com.cn/bug-view-3833.html
export function localdate(val, defaultValue = '-') {
  if (!val) return defaultValue
  // 兼容safari
  return moment(+new Date(val[0], (val[1] - 1), val[2])).format('YYYY-MM-DD')
}
/**
 * 解禁持股数
 * http://localhost:8080/screener/ipo-lift?store=1
 * http://localhost:8080/lift-ban
 * http://localhost:8080/info/project/100010370743?type=2
 * 拟上市公司
 * 目前全局中使用这个规则居多
 * **/
export function liftDateHoldingNum(val) {
  if (!val) {
    return '-'
  }
  if (val >= 10000) {
    return numeral(val / 10000).format('0,0.00') + '万股'
  } else {
    return numeral(val).format('0,0') + '股'
  }
}

//
/*
  金额展示规则
-  0-1亿（不含）之内，数值以“万”为单位，不保留小数。举例：100万，3,000万，5,126万，9,999万
-  1亿-1万亿（不含）之内，数值以“亿”为单位，小数点后保留4位有效数字。举例：1亿，1.21亿，398.135亿，9,999.9999亿
-  1万亿及以上，数值以“万亿”为单位，小数点后保留4位有效数字。举例：1万亿，1.21万亿，398.135万亿，9,999.9999万亿
- 增加千分位符
*/

/*
获取小数位数1
https://www.it1352.com/1011908.html
*/
// const countDecimals = function (value) {
//   if (Math.floor(value) !== value) {
//     return value.toString().split('.')[1].length || 0
//   }
//   return 0
// }

// update by fengjie.liu 不显示具体单位
export function formatMoney(num) {
  // if (!num) return '-'
  // // 100
  // if (num >= 1000000000000) {
  //   // 大于 1 万亿
  //   return number2trillions(num)
  // } else if (num >= 100000000) {
  //   // 大于 1 亿
  //   return number2billion(num)
  // } else if (num >= 1000) {
  //   // 大于 1 万
  //   return number2tenThousand(num)
  // } else {
  //   return number2money(num)
  // }
  return formatMoneyAuto2(num, 1000)
}

/****
 * created by fengjie.liu 2021.10.19
 * 逻辑方法by 浩冰--实体、lp详情
 * 新版数字格式化只有3档：万亿、亿和万
 * ***/
const wan = 10000
const yi = wan * wan
const wanyi = wan * yi

export const formatAmount = function(input, currency = '') {
  console.warn('formatAmount 不准确，使用 num2text 替代')
  let num = Number.parseFloat(input)
  if (!num) return '-'

  let symbol = ''
  if (num < 0) symbol = '-'

  num = Math.abs(num)

  let result = ''
  if (num >= wanyi) {
    // 万亿
    result = numeral(num / wanyi).format('0,0.00') + '万亿'
  } else if (num >= yi) {
    // 亿
    result = numeral(num / yi).format('0,0.00') + '亿'
  } else if (num >= wan) {
    // 万
    result = numeral(num / wan).format('0,0.00') + '万'
  } else {
    result = numeral(num).format('0,0.00')
  }
  return symbol + result + currency
}

/*
update by fengjie.liu 2020.09.16
1、http://localhost:8080/search/detail?wd=%E5%9F%BA%E9%87%91
2、http://localhost:8080/assets/raise/analysis/investment/0e1b42e4d2ba46c7868c5db29d073c55
中的基金管理人列表注册资本展示，无法获取准确的currency信息，添加了1000
{
  foundDate: "2014-04-22"
  fundManagerId: 100010052464
  fundManagerName: "亚信股权投资基金管理（重庆）有限公司"
  highLightList: []
  logo: "http://zo-datashift-test.oss-cn-qingdao.aliyuncs.com/entity_basic_100010052464.jpg"
  regProvince: "重庆市"
  registrationCapital: "200000000.00"
  registrationCapitalCurrency: "人民币"
}
item.registrationCapital|formatMoneyAuto2(1000)
2、http://localhost:8080/assets/project/analysis/partner/32303e451652420ab7b0beeef8498e28
根据优化需求https://zwiki.zerone.com.cn/pages/viewpage.action?pageId=19497825
认缴出资额，只显示金额，去掉币种
{{scope.subCap|formatMoneyAuto2(scope.currency.value,0)}}
— — — — — —— — —— — —— — —— — —— — —— — —— — —— — —— — —— — —— — —— — —— — —— — —
用法1：{{assetDto.raisingScale | formatMoneyAuto2}} 默认显示元（人民币）
用法2：{{assetDto.raisingScale | formatMoneyAuto2(1000)}} 不显示具体单位
— — — — — —— — —— — —— — —— — —— — —— — —— — —— — —— — —— — —— — —— — —— — —— — —
 */
export const formatMoneyAuto2 = function(num, currency = 1, currencyDesc = null) {
  // const units = ['未知', '元', '美元', '加元', '欧元', '日元', '港元', '澳元', '英镑']
  const units = {
    0: '未知',
    1: '元',
    2: '美元',
    3: '加元',
    4: '欧元',
    5: '日元',
    6: '港元',
    7: '澳元',
    8: '英镑',
    1000: ''
  }
  // 输入为以下内容的返回--
  const numberNull = ['', null, undefined, NaN, false]
  if (numberNull.includes(num)) {
    return '-'
  }
  num = Number(num)
  // 输入内容不是number的返回--
  if (Number.isNaN(num)) {
    return '-'
  }
  const num2 = num
  num = Math.abs(num)
  let res
  // 处理单位
  const unit = units[currency] || ''
  if (num >= 1000000000000) {
    // 大于 1 万亿
    res = `${number2trillions(num)}${unit}`
  } else if (num >= 100000000) {
    // 大于 1 亿
    res = `${number2billion(num)}${unit}`
  } else if (num >= 1000) {
    // 大于 1 千
    res = `${number2tenThousand(num)}${unit}`
  } else {
    res = `${number2money(num)}${unit}`
  }
  return currencyDesc ? `${showSymbol(num2)}${res}${currencyDesc}` : `${showSymbol(num2)}${res}`
}

/*
数据为负数时显示-，如-10返回-10,10返回10
created by fengjie.liu 2020.08.06
*/
function showSymbol(value) {
  if (value < 0) {
    return '-'
  } else {
    return ''
  }
}

/*
动态格式化参数
created by fengjie.liu 2020.06.11
*/
// function repeatZero (value) {
//   const len = countDecimals(value)
//   if (len > 4) {
//     return '0,0.0000'
//   } else {
//     return len ? `0,0.${'0'.repeat(len)}` : '0'
//   }
// }

// 小数点统一保留2位小数
const number2money = num => numeral(num).value()
const number2tenThousand = (num, unit = true) => numeral(num / 10000).format('0,0.00') + (unit ? '万' : '')
const number2billion = num => numeral(num / 100000000).format('0,0.00') + '亿'
const number2trillions = num => numeral(num / 1000000000000).format('0,0.00') + '万亿'
// const number2billion = num => numeral(num / 100000000).format(repeatZero(num / 100000000)) + '亿'
// const number2trillions = num => numeral(num / 1000000000000).format(repeatZero(num / 1000000000000)) + '万亿'

export function currency(index, fullName = false) {
  const rmb = fullName ? '人民币' : '元'
  const list = ['未知', rmb, '美元', '加元', '欧元', '日元', '港元', '澳元', '英镑']
  return index < list.length ? list[index] : '-'
}
/**
 * fix scope = {amount: 0, currency: "元"}
 * scope.amount && scope.currency => 0
 * **/
export function amountCurrency(obj) {
  const { amount, currency } = obj
  return amount ? currency : ''
}

/*
created by fengjie.liu 2021.01.08
自定义格式化数字，默认保留小数后2位
https://zhuanlan.zhihu.com/p/35875609
金额千分位
numeral(1234).format('0,0');// 1,234 不带小数
numeral(1234).format('0,0.00');// 1,234.00 保留两位小数
numeralFormat('0,0')
{{ it.total | numeralFormat('0,0') }}
import { numeralFormat } from '@/filters'
numeralFormat(it.total,'0,0')
*/

export function numeralFormat(val, pattern = '0.00') {
  if (excludeZeroIsFalse(val)) {
    return '-'
  }
  return numeral(val).format(pattern)
}

/* EDITING("编辑中", 10),     // 编辑中
PARSING("待匹配", 20),     // 等待解析
PARSING_SUCCESS("待匹配", 30),     // 解析完成
PARSING_REFUSE("待匹配", 30),     // 审核未通过
MATCHING("匹配中", 40),     // 匹配中，发布交易机会、邀请好友
DEALING("交易中", 50),     // 生成deal
DEAL_COMPLETE("交易完成", 100),     // 交易完成
DEAL_STOP("交易终止", 100),     // 交易终止 */
const enumAssetStatus = {
  EDITING: '编辑中',
  PARSING: '等待解析',
  PARSING_SUCCESS: '解析完成',
  PARSING_REFUSE: '审核未通过',
  MATCHING: '撮合匹配中',
  DEALING: '交易中',
  DEAL_COMPLETE: '交易完成',
  DEAL_STOP: '交易终止'
}

export function assetStatusMap(val) {
  if (Object.prototype.hasOwnProperty.call(enumAssetStatus, val)) {
    return enumAssetStatus[val]
  } else {
    return '-'
  }
}

/*
CREATE_DEAL("买家已关注", "关注机会", "潜在意愿买方", "关注的机会", 10),    // 项目入库
DONE_NDA("签署保密协议", "签署保密协议", "签署保密协议的买方", "签署保密协议", 20),    // 签署NDA
DUE_DILIGENCE("买方已发起尽调", "发起尽调", "发起尽调的买方", "发起尽调", 30),    // 尽职调查
DEAL_COMPLETE("完成交易", "完成交易", "完成的交易", "完成的交易", 100),    // 完成交易
DEAL_STOP("交易终止", "交易终止", "交易终止", "交易终止", 100),    // 交易终止
--------------------------
deal_status， 应该是 ： DEAL_COMPLETE 和 DEAL_STOP 不显示 ’终止交易‘ 按钮
--------------------------
 */
/*
created by fengjie.liu 2020.09.14
交易状态映射，应用在交易详情头部信息展示
*/
export function dealStatusMap(val) {
  console.log('dealStatusMap', val)
  const enumDealStatus = {
    CREATE_DEAL: '项目入库',
    DONE_NDA: '签署NDA',
    DUE_DILIGENCE: '尽职调查',
    DEAL_COMPLETE: '完成交易',
    DEAL_STOP: '交易终止'
  }
  return enumDealStatus[val] || '-'
}

/**
 * created by fengjie.liu 2020.08.18
 * 百分比格式化
 *  若num=0会格式化为'-'而不是0.00%
 * 1
 * **/
export const formatPercent = (num, pattern = '0.00%') => {
  console.warn('请使用percent')
  if (excludeZeroIsFalse(num)) {
    return '-'
  }
  return parsePercent(num, pattern)
}

/**
 * 百分比格式化
 * @param {*} num
 * @param {*} pattern
 * @returns
 */
export const percent = (num, pattern = '0.00%') => {
  if (excludeZeroIsFalse(num)) {
    return '-'
  }
  return parsePercent(num, pattern)
}

export const formatPercentLong = str => {
  if (excludeZeroIsFalse(str)) {
    return '-'
  }
  return formatPercentNumber(str)
}

export const shareType = function(value) {
  let result = '-'
  switch (value) {
    case 'EARLY_S':
      result = 'Early S'
      break
    case 'LATE_S':
      result = 'LATE S'
      break
    case 'TAIL_S':
      result = 'TAIL S'
      break
    case 'SPECIAL_PROJECT_FUND':
      result = '项目专项基金'
      break
    case 'OTHER':
      result = '其他'
      break
  }
  return result
}

export function assetTypeDesc(type) {
  switch (type) {
    case 'S_FUND':
      return '基金份额'
    case 'PROJECT':
      return '项目股权'
    case 'FUNDRAISING':
      return '基金募集'
    default:
      return '-'
  }
}

const SCENARIOS = {
  POSITIVE: '乐观',
  NORMAL: '正常',
  NEGATIVE: '悲观'
}

export function mapScenario(key) {
  return SCENARIOS[key]
}

// 根据文件类型获取图标
export function fileIcon(fileType) {
  return getFileIcon(fileType)
}

/**
 * created by fengjie.liu 2021.10.24
 * 默认值展示位'-'
 * 用于列表默认值展示
 * **/
export function getValue(val, symbol = '-') {
  return excludeZeroIsFalse(val) ? symbol : val
}

/**
 * 基金阶段
 * @param {*} key
 * @returns
 */
export function mapFundPeriodText (key) {
  if (!key) return '-'
  const dic = {
    INVESTMENT_PERIOD: '投资期',
    EXIT_PERIOD: '退出期',
    EXTENSION_PERIOD: '延长期'
  }
  return dic[key] || ''
}

/**
 * 文件大小
 * @param {*} key
 * @returns
 */
export function size (size) {
  if (excludeZeroIsFalse(size) || Number.isNaN(Number(size))) {
    return '-'
  }
  return fileSize(size)
}
